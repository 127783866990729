<template>
  <div>
    <b-card title="Другие дела задолжника">
      <BTable
        class="table-bordered"
        :items="dkDebtOther2.results"
        :fields="fields3"
        :busy="loader"
        show-empty
        responsive
      >
        <template #empty>
          <empty />
        </template>
        <template #table-busy>
          <div class="d-flex align-items-center flex-column p-3">
            <b-spinner
              variant="primary"
            />
            <span>Загрузка...</span>
          </div>
        </template>
        <template #cell(operator)="props">
          <pre>{{ props.item }}</pre>
        </template>
        <template #cell(pay_his)="props">
          <div v-if="props.item.BASIS">
            <div
              v-for="(e, index) in props.item.BASIS.PAY_HISTORY"
              :key="index"
            >
              <ul
                class="d-flex align-items-center justify-content-between mb-0"
              >
                <li style="font-size: 11px">
                  {{ e.PAY_DATE }}
                </li>
                <small class="ml-50">
                  {{ $_moneyFormatter(e.PAY_AMOUNT) }},
                </small>
              </ul>
            </div>
          </div>
        </template>

        <template #cell(debtors)="data">
          <div
            class="text-primary cursor-pointer pri__text"
            @click="changeRoute(data)"
          >
            <!--           <router-link-->
            <!--               v-if="data.item.BORROWER"-->
            <!--               :to="`/collectorDetail/${data.item.id}`"-->
            <!--           >-->
            {{ data.item.BORROWER.FULL_NAME }}
            <!--           </router-link>-->
          </div>
        </template>

        <template #cell(BASIS)="props">
          <div v-if="props.item.BASIS">
            <span v-if="props.item.BASIS.DO_STAGE === 'voluntary_payment'">Добровольная оплата</span>
            <span v-else-if="props.item.BASIS.DO_STAGE === 'claim'">Претензия</span>
            <span v-if="props.item.BASIS.DO_STAGE === 'judical_work'">Судебная работа</span>
            <span v-else-if="props.item.BASIS.DO_STAGE === 'executive_document'">Исполнительный документ</span>
            <span v-if="props.item.BASIS.DO_STAGE === 'executive_work'">Исполнительное производство</span>
            <span v-else-if="props.item.BASIS.DO_STAGE === 'stopped'">Приостановлено</span>
            <span v-if="props.item.BASIS.DO_STAGE === 'paid'">Погашен</span>
          </div>
        </template>

        <template #cell(SUM)="props">
          <span v-if="props.item.BASIS && props.item.BASIS.DEBT">
            {{ $_moneyFormatter(props.item.BASIS.DEBT) }}
          </span>
        </template>
        <template #cell(DEBT_STATUS)="props">
          <div v-if="props.item.DEBT_STATUS">
            <b-badge
              :variant="props.item.DEBT_STATUS"
            >
              {{ getTextDebtStatus(props.item.DEBT_STATUS) }}
            </b-badge>
          </div>
        </template>
      </BTable>

      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap mr-1">Показать по</span>
          <b-form-select
            v-model="params.page_size"
            :options="[5, 10, 20]"
            class="mx-1"
            @change="refresh"
          />
          <span class="ml-1 text-nowrap"> строк( {{ dkDebtOther2.count }} )</span>
        </div>
        <b-pagination
          v-model="params.page"
          :total-rows="dkDebtOther2.count"
          :per-page="params.page_size"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="changePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormSelect, BPagination, BTable, BSpinner, BBadge,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import Empty from '@components/empty.vue'
import router from '@/router'

export default {
  components: {
    BCard,
    Empty,
    BTable,
    BFormSelect,
    BPagination,
    BSpinner,
    BBadge,
  },
  data() {
    return {
      fields3: [
        {
          key: 'id',
          label: '№',
          sortable: false,
        },
        {
          key: 'pay_his',
          label: 'Последние платежи',
          sortable: false,
        },
        {
          key: 'LAST_ACTION_DATE',
          label: 'ДАТА ПОСЛЕДНЕГО ДЕЙСТВИЯ',
          sortable: false,
        },
        {
          key: 'debtors',
          label: 'ФИО ДОЛЖНИКА',
          sortable: false,
        },
        {
          key: 'SUM',
          label: 'Сумма долга',
          sortable: false,
        },
        {
          label: 'Оператор',
          key: 'DK_OPERATOR.full_name',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
        {
          key: 'DEBT_STATUS',
          label: 'Статус',
          sortable: false,
        },
      ],
      loader: false,
      params: {
        page: 1,
        page_size: 10,
      },
    }
  },
  computed: {
    ...mapState('collectorDetail', ['dkDebt', 'dkDebtOther2']),
  },
  watch: {
    dkDebt: {
      handler(val) {
        if (val && val.BORROWER) {
          this.refresh()
        }
      },
    },
  },
  mounted() {
    if (this.dkDebt.BORROWER.id) {
      this.refresh()
    }
  },
  methods: {
    ...mapActions('collectorDetail', ['FETCH_DK_DEBT_OTHER2', 'FETCH_DK_DEBT_ID']),
    ...mapMutations('collectorDetail', ['SET_CALL']),

    async refresh() {
      try {
        this.loader = true
        await this.FETCH_DK_DEBT_OTHER2({
          BORROWER: this.dkDebt.BORROWER.id,
          EXCLUDE_DEBT_ID: this.$route.params.id,
          ...this.params,
        })
          .then(() => {
            const arr = this.dkDebtOther2.results

            for (let i = 0; i < arr.length; i++) {
              if (arr[i].id == this.$route.params.id) {
                arr.splice(i, 1)
              }
            }
          })
      } catch (e) {
        this.loader = false
      }
      this.loader = false
    },

    getTextDebtStatus(value) {
      const message = {
        secondary: 'Неправильный номер',
        primary: 'Связь прервалась',
        'light-danger': 'Отказ',
        dark: 'Банкрот',
        danger: 'Неперспективный',
        info: 'Не ответил',
        warning: 'Обещание',
      }

      return message[value] || ''
    },

    changeRoute(data) {
      this.SET_CALL(false)
      const url = router.resolve({
        name: 'collector-id',
        params: { id: data.item.id },
      }).href
      window.open(url, '_blank')

      // this.$router.push(`/collector/${data.item.id}/`)
    },

    changePage(page) {
      this.params.page = page

      this.refresh()
    },
  },
}
</script>
