<template>
  <b-modal
    id="modalAddWork"
    :title="editItem.id ? 'Редактировать работа' : 'Добавить работа'"
    cancel-variant="outline-secondary"
    centered
    ok-title="Сохранить"
    :ok-variant="editItem.id ? 'warning' : 'primary'"
    cancel-title="Отменить"
    no-close-on-backdrop
    @ok="onSubmit"
    @show="showModal"
  >
    <validation-observer ref="validation">
      <b-form-group label="Вид дохода">
        <validation-provider
          #default="{ errors }"
          name="Вид дохода"
          rules="required"
        >
          <v-select
            v-model="formData.INCOME_TYPE"
            label="title"
            :reduce="p => p.value"
            :options="incomeType"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

      </b-form-group>

      <b-form-group label="Доход">
        <validation-provider
          #default="{ errors }"
          name="Доход"
          rules="required"
        >
          <BFormInput
            v-model="formData.INCOME"
            type="number"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

      </b-form-group>

      <b-form-group label="Справка о доходах">
        <v-select
          v-model="formData.NDFL2"
          label="title"
          :reduce="p=>p.value"
          :options="incomeStatement"
        >
          <template #no-options>
            К сожалению, нет подходящих вариантов
          </template>
        </v-select>
      </b-form-group>

      <b-form-group label="Компания">
        <validation-provider
          #default="{ errors }"
          name="Компания"
          rules="required"
        >
          <v-select
            v-model="formData.COMPANY"
            label="COMPANY_NAME"
            :reduce="p =>p.COMPANY_ID"
            :options="debtCompanyList.results"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Инн">
        <validation-provider
          #default="{ errors }"
          name="Инн"
          rules="required"
        >
          <BFormInput
            v-model="formData.INN"
            type="number"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Комментарий">
        <BFormTextarea
          v-model="formData.COMMENT"
          rows="3"
        />
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormInput,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import { required } from '@core/utils/validations/validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'AddWorkModal',
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    editItem: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      required,
      formData: {
        INCOME_TYPE: null,
        INCOME: null,
        COMPANY: null,
        INN: null,
        COMMENT: null,
        NDFL2: null,
      },
      incomeType: [
        {
          title: 'Пенсия',
          value: 'work',
        },
        {
          title: 'Другое',
          value: 'pension',
        },
        {
          title: 'Работающий',
          value: 'other',
        },
        {
          title: 'пенсионер',
          value: 'work_pensioner',
        },
      ],
      incomeStatement: [
        {
          title: 'Не указано',
          value: 'not_showed',
        },
        {
          title: 'Есть',
          value: 'est',
        },
        {
          title: 'Нет',
          value: 'net',
        },
      ],
    }
  },
  computed: {
    ...mapState('cases', ['debtListId', 'debtCompanyList']),
  },
  mounted() {
    this.FETCH_SK_DEBT_COMPANY_LIST({ page_size: 1000 })
  },
  methods: {
    ...mapActions('cases', ['CREATE_SK_STATEMENT_OF_DEBT', 'FETCH_SK_STATEMENT_OF_DEBT', 'FETCH_SK_DEBT_COMPANY_LIST', 'UPDATE_SK_STATEMENT_OF_DEBL']),

    onSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()

      this.$refs.validation.validate()
        .then(success => {
          if (success) {
            if (this.editItem.id) {
              this.UPDATE_SK_STATEMENT_OF_DEBL({
                id: this.editItem.id,
                ...this.formData,
              })
                .then(() => {
                  this.$nextTick(() => {
                    this.FETCH_SK_STATEMENT_OF_DEBT({ id: this.$route.params.id })
                    this.$bvModal.hide('modalAddWork')
                    this.$_okToast()
                  })
                })
            } else {
              const req = {
                DOSSIER_ID: this.$route.params.id,
                CONTACT: this.debtListId.BORROWER.id,
                ...this.formData,
              }
              this.CREATE_SK_STATEMENT_OF_DEBT(req)
                .then(() => {
                  this.$nextTick(() => {
                    this.FETCH_SK_STATEMENT_OF_DEBT({ id: this.$route.params.id })
                    this.$bvModal.hide('modalAddWork')
                    this.$_okToast()
                  })
                })
            }
          }
        })
    },

    showModal() {
      this.$nextTick(() => {
        this.formData = {
          INCOME_TYPE: null,
          INCOME: '',
          income_doc: null,
          COMPANY: '',
          INN: '',
          COMMENT: '',
          NDFL2: '',
        }
        if (typeof this.editItem === 'object' && this.editItem !== null) {
          this.formData.INCOME_TYPE = this.editItem.INCOME_TYPE
          this.formData.INCOME = this.editItem.INCOME
          this.formData.COMPANY = this.editItem.COMPANY
          this.formData.INN = this.editItem.INN
          this.formData.COMMENT = this.editItem.COMMENT
          this.formData.NDFL2 = this.editItem.NDFL2
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
