<template>
  <b-modal
      id="addEditDoc"
      centered
      :title="editDoc.id ? 'Редактировать документы' : 'Добавить документы'"
      :ok-variant="editDoc.id ? 'warning' : 'primary'"
      ok-title="Сохранить"
      cancel-variant="outline-secondary"
      cancel-title="Отмена"
      responsive
      no-close-on-backdrop
      @show="openShowModal"
      @ok.prevent="createDoc"
  >
    <validation-observer ref="validation">
      <b-form-group label="Дата документа">
        <validation-provider
            #default="{ errors }"
            name="Дата документа"
            rules="required"
        >
          <input
              id="passport_expire_date"
              v-model="formData.date_document"
              type="date"
              class="form-control"
          >
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Вид документ">
        <div class="d-flex align-items-center w-100">
          <v-select
              v-model="formData.tags"
              multiple
              class="w-100"
              label="name"
              :options="utilsDocType.results"
          />
          <b-button
              class="ml-50"
              size="sm"
              variant="success"
              @click="openModal"
          >
            <feather-icon icon="PlusIcon"/>
          </b-button>
        </div>
      </b-form-group>

      <b-form-group label="Файлы">
        <div class="d-flex align-items-center">
          <BFormFile
              ref="file-input"
              v-model="formData.file"
              multiple
              :placeholder="formData.file_title ? formData.file_title : 'Выбрать файл'"
          />
          <b-button
              size="sm"
              class="p-50 ml-50"
              variant="danger"
              @click="clearFiles"
          >
            <feather-icon icon="TrashIcon"/>
          </b-button>
        </div>
      </b-form-group>

      <b-form-group label="Полезная информация">
        <BFormInput
            v-model="formData.title"
        />
      </b-form-group>

      <b-form-group label="Комментарий">
        <b-form-textarea
            v-model="formData.comment"
        />
      </b-form-group>
    </validation-observer>

    <typeDocument
        :type="type"
        :modal-id="modalId"
    />
  </b-modal>
</template>

<script>
import typeDocument from '@components/typeDocument/index.vue'
import { mapActions, mapState } from 'vuex'
import {
  BButton, BFormFile, BFormGroup, BFormInput, BFormTextarea, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment/moment'

export default {
  components: {
    typeDocument,
    BModal,
    BFormFile,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BButton,
    vSelect,
  },
  props: {
    editDoc: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      formData: {
        title: '',
        file: [],
        date_document: '',
        comment: '',
        tags: [],
        file_title: '',
      },
      modalId: 'modal1',
      type: 'basis-dk',
      tags: true
    }
  },
  computed: {
    ...mapState('reference', ['utilsDocType']),
    ...mapState('collectorDetail', ['dkDebt']),

    getUserId() {
      return JSON.parse(localStorage.getItem('userData'))
    },
  },
  methods: {
    ...mapActions('reference', ['FETCH_UTILS_DOC_TYPE']),
    ...mapActions('cases', ['CREATE_DEBT_DOCUMENT', 'UPDATE_DEBT_DOCUMENT']),

    createDoc() {
      this.tags = false
      this.$refs.validation.validate()
          .then(success => {
            if (success) {
              if (this.editDoc.id) {
                this.editDocs()
              } else {
                const formData = new FormData()
                formData.append('date_document', this.formData?.date_document)
                // eslint-disable-next-line no-restricted-syntax
                for (const key of this.formData?.file) {
                  if (typeof key !== 'undefined') formData.append('file', key)
                }
                // eslint-disable-next-line no-restricted-syntax
                for (const key of this.formData?.tags) {
                  if (typeof key !== 'undefined') formData.append('tags', key.id)
                }
                formData.append('title', this.formData?.title)
                formData.append('comment', this.formData?.comment)
                formData.append('author', this.getUserId.id)
                formData.append('content_type', this.dkDebt.CONTENT_TYPE)
                formData.append('object_id', this.dkDebt.id)
                this.CREATE_DEBT_DOCUMENT(formData)
                    .then(() => {
                      this.$_okToast()
                      this.$bvModal.hide('addEditDoc')
                      this.$emit('refresh')
                      this.clearFormData()
                      this.tags = true
                    })
                    .catch(err => {
                      this.$_errorToast(err)
                      this.tags = true
                    })
              }
            }
          })
    },

    editDocs() {
      this.tags = false
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)

      if (this.formData?.file) {
        for (const key of this.formData?.file) {
          if (typeof key !== 'undefined') formData.append('file', key)
        }
      }

      if (this.formData?.tags) {
        for (const tag of this.formData?.tags) {
          if (tag && tag.id) {
            if (typeof tag !== 'undefined') formData.append('tags', tag.id)
          } else {
            if (typeof tag !== 'undefined') formData.append('tags', tag)
          }
        }
      }

      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId.id)
      formData.append('content_type', this.dkDebt.CONTENT_TYPE)
      formData.append('object_id', this.dkDebt.id)
      formData.append('id', this.editDoc?.id)

      this.UPDATE_DEBT_DOCUMENT(formData)
          .then(() => {
            this.$bvModal.hide('addEditDoc')
            this.$_okToast()
            this.$emit('refresh')
            this.clearFormData()
            this.tags = true
          })
          .catch(err => {
            this.$_errorToast(err)
            this.tags = true
          })
    },

    openShowModal() {
      this.FETCH_UTILS_DOC_TYPE({
        page_size: 1000,
        type: this.type,
      })

      if (typeof this.editDoc === 'object' && this.editDoc !== null && this.editDoc.id) {
        let files = ''
        files += this.editDoc?.files?.map(el => ` ${el.file_title}`)

        this.formData.date_document = moment(this.editDoc.date_document, 'YYYY-MM-DD')
            .format('YYYY-MM-DD')
        this.formData.action_type = this.editDoc.action_type
        this.formData.title = this.editDoc.title
        this.formData.file_title = files
        this.formData.comment = this.editDoc.comment || ''
        this.formData.tags = this.editDoc.tags
      } else {
        this.formData = {
          title: '',
          file: [],
          date_document: '',
          comment: '',
          tags: [],
          file_title: '',
        }
      }
    },

    clearFiles() {
      this.$refs['file-input'].reset()
    },

    openModal() {
      this.$nextTick(() => {
        this.$bvModal.show(this.modalId)
      })
    },

    clearFormData() {
      this.formData = {
        title: '',
        file: [],
        date_document: '',
        comment: '',
        tags: [],
        file_title: '',
      }
    }
  },
}
</script>
