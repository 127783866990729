<template>
  <b-modal
    id="contractModal"
    title="Информация по договору"
    centered
    size="lg"
    no-close-on-backdrop
    cancel-title="Отмена"
    ok-title="Сохранить"
    @show="openShowModal"
    @ok.prevent="onSubmit"
  >
    <b-row>
      <b-col md="6">
        <b-form-group label="Сумма задолжности">
          <b-form-input />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
  },
  data() {
    return {
      value: {

      },
    }
  },
  methods: {
    openShowModal() {

    },

    onSubmit() {

    },
  },
}
</script>
