<template>
  <div class="mobile__resp">
    <!--    <pre>{{ skHeaderSta }}</pre>-->
    <!--    {{ skHeaderSta.today_auto_call }}-->

    <b-card
      no-body
      class="mb-50"
      :class="active === 4 ? 'bg-active cursor-pointer' : 'cursor-pointer'"
      @click="$emit('getActive', 4)"
    >
      <div class="p-25 text-center">
        <small class="mb-0 d-flex align-items-center justify-content-center">
          <feather-icon
            class="mr-75"
            :class="active === 4 ? 'text-white' : 'text-primary'"
            icon="PhoneCallIcon"
          />
          <span>Звонки от менеджеров</span>
        </small>
      </div>
      <div class="row text-center mx-0">
        <div class="border-top border-right d-flex align-items-between flex-column py-25 col-6">
          <small
            class="mb-0"
            :class="active === 4 ? 'text-white' : 'text-danger'"
          >
            Сегодня
          </small>
          <small
            class="font-weight-bolder mb-0"
            :class="active === 4 ? 'text-white' : 'text-danger'"
          >
            {{ skHeaderSta.today_manual_call }}
          </small>
        </div>
        <div class="border-top d-flex align-items-between flex-column py-25 col-6">
          <small
            class="card-text mb-0"
            :class="active === 4 ? 'text-white' : 'text-primary'"
          >
            Общий
          </small>
          <small
            class="font-weight-bolder mb-0"
            :class="active === 4 ? 'text-white' : 'text-primary'"
          >
            {{ skHeaderSta.manual_call }}
          </small>
        </div>
      </div>

    </b-card>

    <!--    <b-card-->
    <!--      no-body-->
    <!--      class="mb-1 paddinggg"-->
    <!--      :class="active === 4 ? 'bg-active cursor-pointer' : 'cursor-pointer'"-->
    <!--      @click="$emit('getActive', 4)"-->
    <!--    >-->
    <!--      <div-->
    <!--        class="d-flex align-items-center justify-content-between"-->
    <!--      >-->
    <!--        <feather-icon-->
    <!--          class="ml-75"-->
    <!--          size="18"-->
    <!--          :class="active === 4 ? 'text-white' : 'text-primary'"-->
    <!--          icon="PhoneCallIcon"-->
    <!--        />-->

    <!--        <div class="truncate d-flex flex-column text-right">-->
    <!--          <h4 class="mb-25 font-weight-bolder">-->
    <!--            {{ skHeaderSta.manual_call }}-->
    <!--            {{ skHeaderSta.today_manual_call }}-->
    <!--          </h4>-->

    <!--          <span class="fs-13">Звонки от менеджеров</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </b-card>-->

    <b-card
      no-body
      class="mb-50"
      :class="active === 6 ? 'bg-active cursor-pointer' : 'cursor-pointer'"
      @click="$emit('getActive', 6)"
    >
      <div class="p-25 text-center">
        <small class="mb-0 d-flex align-items-center justify-content-center">
          <feather-icon
            class="mr-75"
            :class="active === 6 ? 'text-white' : 'text-primary'"
            icon="PhoneCallIcon"
          />
          <span>Автозвонки</span>
        </small>
      </div>
      <div class="row text-center mx-0">
        <div class="border-top border-right d-flex align-items-between flex-column py-25 col-6">
          <small
            class="mb-0"
            :class="active === 6 ? 'text-white' : 'text-danger'"
          >
            Сегодня
          </small>
          <small
            class="font-weight-bolder mb-0"
            :class="active === 6 ? 'text-white' : 'text-danger'"
          >
            {{ skHeaderSta.today_auto_call }}
          </small>
        </div>
        <div class="border-top d-flex align-items-between flex-column py-25 col-6">
          <small
            class="card-text mb-0"
            :class="active === 6 ? 'text-white' : 'text-primary'"
          >
            Общий
          </small>
          <small
            class="font-weight-bolder mb-0"
            :class="active === 6 ? 'text-white' : 'text-primary'"
          >
            {{ skHeaderSta.auto_call }}
          </small>
        </div>
      </div>

    </b-card>

    <!--    <b-card-->
    <!--      no-body-->
    <!--      class="mb-1 paddinggg"-->
    <!--      :class="active === 6 ? 'bg-active cursor-pointer' : 'cursor-pointer'"-->
    <!--      @click="$emit('getActive', 6)"-->
    <!--    >-->
    <!--      <div class="d-flex align-items-center justify-content-between">-->
    <!--        <feather-icon-->
    <!--          class="ml-75"-->
    <!--          size="18"-->
    <!--          :class="active === 6 ? 'text-white' : 'text-primary'"-->
    <!--          icon="PhoneCallIcon"-->
    <!--        />-->

    <!--        <div class="truncate d-flex flex-column text-right">-->
    <!--          <h4 class="mb-25 font-weight-bolder">-->
    <!--            {{ skHeaderSta.auto_call }}-->
    <!--          </h4>-->

    <!--          <span class="fs-13">Автозвонки</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </b-card>-->

    <b-card
      no-body
      class="mb-50"
      :class="active === 8 ? 'bg-active cursor-pointer' : 'cursor-pointer'"
      @click="$emit('getActive', 8)"
    >
      <div class="p-25 text-center">
        <small class="mb-0 d-flex align-items-center justify-content-center">
          <feather-icon
            class="mr-75"
            :class="active === 8 ? 'text-white' : 'text-primary'"
            icon="PhoneCallIcon"
          />
          <span>СМС информирование</span>
        </small>
      </div>
      <div class="row text-center mx-0">
        <div class="border-top border-right d-flex align-items-between flex-column py-25 col-6">
          <small
            class="mb-0"
            :class="active === 8 ? 'text-white' : 'text-danger'"
          >
            Сегодня
          </small>
          <small
            class="font-weight-bolder mb-0"
            :class="active === 8 ? 'text-white' : 'text-danger'"
          >
            {{ skHeaderSta.today_auto_sms }}
          </small>
        </div>
        <div class="border-top d-flex align-items-between flex-column py-25 col-6">
          <small
            class="card-text mb-0"
            :class="active === 8 ? 'text-white' : 'text-primary'"
          >
            Общий
          </small>
          <small
            class="font-weight-bolder mb-0"
            :class="active === 8 ? 'text-white' : 'text-primary'"
          >
            {{ skHeaderSta.auto_sms }}
          </small>
        </div>
      </div>

    </b-card>

    <!--    <b-card-->
    <!--      no-body-->
    <!--      class="mb-1 paddinggg"-->
    <!--      :class="active === 8 ? 'bg-active cursor-pointer' : 'cursor-pointer'"-->
    <!--      @click="$emit('getActive', 8)"-->
    <!--    >-->
    <!--      <div class="d-flex align-items-center justify-content-between">-->
    <!--        <feather-icon-->
    <!--          class="ml-75"-->
    <!--          size="18"-->
    <!--          :class="active === 8 ? 'text-white' : 'text-primary'"-->
    <!--          icon="MailIcon"-->
    <!--        />-->

    <!--        <div class="truncate d-flex flex-column text-right">-->
    <!--          <h4 class="mb-25 font-weight-bolder">-->
    <!--            {{ skHeaderSta.auto_sms }}-->
    <!--          </h4>-->

    <!--          <span class="fs-13">СМС информирование</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </b-card>-->
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BCard,
  },
  props: {
    active: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState('collectorDetail', ['skHeaderSta']),
  },
}
</script>
<style lang="scss">
.bg-active {
  background-color: #147bfc !important;

  h4 {
    color: #fff;
  }

  span {
    color: #fff;
  }
}

</style>
