<template>
  <b-modal
    id="guarantor"
    title="Поручитель"
    size="lg"
    centered
    cancel-title="Отмена"
    ok-title="Сохранить"
    @show="openShowModal"
  >
    <p
      v-if="guarantorVal.length === 0"
      class="mb-0 text-center p-3"
    >
      Нет данных
    </p>
    <b-row
      v-for="(item, index) in guarantorVal"
      v-else
      :key="index"
    >
      <b-col>
        <b-form-group label="Ф.И.О.">
          <b-form-input
            v-model="item.FULL_NAME"
            @input="updateValues(item)"
          />
        </b-form-group>
      </b-col>

      <!--      <b-col>-->
      <!--        <b-form-group label="Тип заемщика.">-->
      <!--          <b-form-input-->
      <!--            v-model="item.position"-->
      <!--            @input="updateValues(item)"-->
      <!--          />-->
      <!--        </b-form-group>-->
      <!--      </b-col>-->

      <b-col>
        <b-form-group label="Номер телефона">
          <b-form-input
            v-model="item.INCOME_SOURCE_PHONE"
            @input="updateValues(item)"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-if="false"
        md="1"
        class="text-right"
      >
        <b-button
          style="margin-top: 30px"
          variant="danger"
          class="p-50"
          size="sm"
          @click="deleteManager(index)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </b-col>
    </b-row>

    <div slot="modal-footer">
      <b-spinner
        v-if="update"
        variant="primary"
        small
      />

      <feather-icon
        v-else
        size="20"
        icon="CheckIcon"
        class="text-primary"
      />
    </div>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
  },
  props: {
    guarantorArr: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      update: false,
      guarantorVal: [
        {
          FULL_NAME: null,
          position: null,
          INCOME_SOURCE_PHONE: null,
        },
      ],
    }
  },
  methods: {
    ...mapActions('collectorDetail', ['CREATE_GUARANTOR', 'FETCH_DK_DEBT_ID']),

    updateValues(items) {
      this.update = true
      clearTimeout(this.delay)

      this.delay = setTimeout(async () => {
        const req = {
          id: items.id,
          data: {
            FULL_NAME: items.FULL_NAME,
            INCOME_SOURCE_PHONE: items.INCOME_SOURCE_PHONE,
          },
        }

        await this.CREATE_GUARANTOR(req)
          .then(() => {
            this.update = false
            this.FETCH_DK_DEBT_ID({ id: this.$route.params.id })
          }).catch(e => {
            this.$_errorToast(e)
          })
      }, 500)
    },

    openShowModal() {
      this.guarantorVal = JSON.parse(JSON.stringify(this.guarantorArr))
    },
  },
}
</script>
