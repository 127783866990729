<template>
  <div>
    <b-card title="Обещания">
      <BTable
        class="my-2 table-bordered"
        :items="promiseList.results"
        :fields="fields"
        show-empty
        responsive
        :busy="loader"
        empty-text="Нет данных"
      >
        <template #empty>
          <empty />
        </template>
        <template #table-busy>
          <div class="d-flex align-items-center flex-column p-3">
            <b-spinner
              variant="primary"
            />
            <span>Загрузка...</span>
          </div>
        </template>
        <template #cell(promised_amount)="props">
          {{ $_moneyFormatter(props.item.promised_amount) }}
        </template>

        <template #cell(status_promise)="props">
          <b-badge
            :variant="props.item.status_promise.color"
          >
            {{ props.item.status_promise.context }}
          </b-badge>
        </template>
      </BTable>

      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap mr-1">Показать по</span>
          <b-form-select
            v-model="params.page_size"
            :options="['5', '10', '15']"
            class="mx-1"
            @change="changePerPage"
          />
          <span class="ml-1 text-nowrap"> строк( {{ promiseList.count }} )</span>
        </div>
        <div>
          <b-pagination
            v-model="params.page"
            :total-rows="promiseList.count"
            :per-page="params.page_size"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @change="changePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BSpinner,
  BFormSelect,
  BPagination,
  BBadge,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import empty from '@/components/empty.vue'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    BFormSelect,
    BPagination,
    BBadge,
    empty,
  },
  data() {
    return {
      params: {
        page_size: 5,
        page: 1,
      },
      fields: [
        {
          label: 'Дата создания',
          key: 'created_date',
        },
        {
          label: 'Сумма обещания',
          key: 'promised_amount',
        },
        {
          label: 'Дата обещания',
          key: 'promised_date',
        },
        {
          label: 'Пользователь',
          key: 'user',
        },
        {
          label: 'Статус',
          key: 'status_promise',
        },
      ],
      loader: false,
    }
  },
  computed: {
    ...mapState('collectorDetail', ['promiseList']),
  },
  mounted() {
    this.refresh()
  },
  methods: {
    ...mapActions('collectorDetail', ['FETCH_PROMISE_LIST']),

    async refresh() {
      try {
        this.loader = true
        await this.FETCH_PROMISE_LIST({
          debt: this.$route.params.id,
          ...this.params,
        })
      } catch (e) {
        await this.$_errorToast(e)
        this.loader = false
      }
      this.loader = false
    },

    changePerPage(pageSize) {
      this.params.page_size = pageSize

      this.refresh()
    },

    changePage(page) {
      this.params.page = page

      this.refresh()
    },
  },
}
</script>
