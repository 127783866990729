<template>
  <div class="client_info">
    <b-overlay
      :show="busy"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-card
        no-body
        class="p-1 mb-1"
      >
        <div class="d_flex">
          <h5 class="text-left font-weight-bold mb-0">
            Timezone:
          </h5>
          <span
            v-if="dkDebt.BORROWER"
            class="ml-50 text-right text-primary font-weight-bold"
          >
            {{ dkDebt.BORROWER.TIME_ZONE }}
          </span>
        </div>

        <div class="d_flex">
          <h5 class="text-left font-weight-bold mb-0">
            Разница во времени:
          </h5>
          <span
            v-if="dkDebt.BORROWER"
            class="ml-50 text-right text-primary font-weight-bold"
          >
            {{ formattedTimeZoneOffset }}
          </span>
        </div>

        <!--        <h4-->
        <!--          v-if="dkDebt.BORROWER.TIME_ZONE || dkDebt.BORROWER.TIME_ZONE_UTC !== null"-->
        <!--          class="mb-0"-->
        <!--        >-->
        <!--          Timezone: {{ dkDebt.BORROWER.TIME_ZONE }}-->
        <!--          UTC: {{ formattedTimeZoneOffset }}-->
        <!--        </h4>-->
        <div class="d_flex">
          <span class="text-left">Отказ от взаимодействия с 3ми лицами:</span>
          <span
            v-if="dkDebt.BASIS"
            class="ml-50 text-right text-primary font-weight-bold cursor-pointer"
            @click="update3RD"
          >
            {{ dkDebt.BASIS.REFUSED_FROM_3RD_PARTIES === 'Y' ? 'Да' : 'Нет' }}
          </span>
        </div>

        <div class="d_flex">
          <span class="text-left">Умер:</span>
          <span
            v-if="dkDebt.BORROWER"
            class="ml-50 text-right text-primary font-weight-bold cursor-pointer"
            @click="updateDie"
          >
            {{ dkDebt.BORROWER.IS_DEAD ? 'Да' : 'Нет' }}
          </span>
        </div>
      </b-card>
    </b-overlay>
    <b-card
      no-body
      class="p-1 mb-1"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="info = !info"
        >
          <buttonCollapse
            :item="info"
          />
          <h5 class="mb-0 text-primary ml-1">
            Информация о заказчике
          </h5>
        </div>

        <b-button
          v-b-modal:debtor
          variant="warning"
          size="sm"
          class="p-50"
        >
          <feather-icon
            icon="Edit3Icon"
          />
        </b-button>
      </div>

      <div
        v-if="info"
        class="mt-2"
      >
        <div class="d_flex">
          <span class="text-left">Взыскатель:</span>
          <span class="ml-50 text-right"> {{ dkDebt.CLAIMER_CRM_COMPANY_ID.COMPANY_NAME }} </span>
        </div>
        <div class="d_flex">
          <span class="text-left">Адрес организации:</span>
          <span class="ml-50 text-right"> {{ dkDebt.CLAIMER_CRM_COMPANY_ID.address }} </span>
        </div>
        <div class="d_flex">
          <span class="text-left">Сайт</span>
          <span class="ml-50 text-right"> {{ dkDebt.CLAIMER_CRM_COMPANY_ID.web_site }} </span>
        </div>
        <div class="d_flex">
          <span class="text-left">Номер телефона</span>
          <span class="ml-50 text-right"> {{ dkDebt.CLAIMER_CRM_COMPANY_ID.phone_number }} </span>
        </div>
      </div>
    </b-card>

    <b-card
      no-body
      class="p-1 mb-1"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="info2 = !info2"
        >
          <buttonCollapse
            :item="info2"
          />
          <h5 class="mb-0 text-primary ml-1">
            Информация по должнику
          </h5>
        </div>

        <b-button
          v-b-modal:debtInfo
          variant="warning"
          size="sm"
          class="p-50"
        >
          <feather-icon
            icon="Edit3Icon"
          />
        </b-button>
      </div>

      <div
        v-if="info2"
        class="mt-2"
      >
        <div class="d_flex">
          <span>Ф.И.О:</span>
          <span class="ml-50 text-right"> {{ dkDebt.BORROWER.FULL_NAME }} </span>
        </div>
        <div class="d_flex">
          <span>Партнер:</span>
          <span class="ml-50 text-right"> {{ dkDebt.BORROWER.PARTNER }} </span>
        </div>
        <div class="d_flex">
          <span>Доходы:</span>
          <span
            class="ml-50 hover-text-underline"
            @click="openIncomeModal"
          >Все источники дохода </span>
        </div>
        <div class="d_flex">
          <span>Имущество:</span>
          <span
            class="ml-50 hover-text-underline"
            @click="openModalProperty"
          >
            Смотреть
          </span>
        </div>
        <div>
          <span v-b-toggle.collapse-1>
            Персональные данные
            <feather-icon
              class="text-primary"
              icon="ChevronDownIcon"
            />
          </span>
          <b-collapse
            id="collapse-1"
            class="ml-2"
          >
            <div class="d_flex mt-1">
              <span>Дата рождения:</span>
              <span class="ml-50"> {{ dkDebt.BORROWER.BIRTH_DATE }} </span>
            </div>
            <div class="d_flex W-100">
              <span class="w-50">Домашний адрес:</span>
              <span
                class="ml-50 w-50 text-right"
              >{{ dkDebt.BORROWER.HOME_ADDRESS ? desiredVariant(dkDebt.BORROWER.HOME_ADDRESS) : '' }}</span>
            </div>
            <div class="d_flex w-100">
              <span class="w-50">Адрес для направления корреспонденции:</span>
              <span
                class="ml-50 w-50 text-right"
              >{{ dkDebt.BORROWER.PERM_REG ? desiredVariant(dkDebt.BORROWER.PERM_REG) : '' }}</span>
            </div>
            <!--            <div class="d_flex">-->
            <!--              <span>Дата выдачи паспорта:</span>-->
            <!--              <span class="ml-50"> {{ dkDebt.BORROWER.PASSPORT_GIVEN_DATE }} </span>-->
            <!--            </div>-->

            <div class="d_flex ">
              <span>Паспорт серия :</span>
              <span class="ml-50 text-right"> {{ dkDebt.BORROWER.PASSPORT_SERIA }} </span>
            </div>

            <div class="d_flex">
              <span>Номер паспорта:</span>
              <span class="ml-50 text-right"> {{
                dkDebt.BORROWER.PASSPORT_NUMBER ? parseInt(dkDebt.BORROWER.PASSPORT_NUMBER) : ''
              }} </span>
            </div>

            <!--            <div class="d_flex">-->
            <!--              <span>Трудоустройство:</span>-->
            <!--              <span class="ml-50"> {{ dkDebt.BORROWER.POSITION }} </span>-->
            <!--            </div>-->

            <div class="d_flex">
              <span>Электронная почта:</span>
              <a
                :href="`mailto:${dkDebt.BORROWER.EMAIL}`"
                class="ml-50 text-right w-100 text-ellipsis overflow-hidden"
              >
                {{ dkDebt.BORROWER.EMAIL }}
              </a>
            </div>

            <div class="d_flex">
              <span>Семейное положение:</span>
              <span class="ml-50 text-right"> {{ dkDebt.BORROWER.MARITAL_STATUS }} </span>
            </div>
          </b-collapse>
        </div>
      </div>
    </b-card>

    <b-card
      no-body
      class="p-1 mb-1"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="info3 = !info3"
        >
          <buttonCollapse
            :item="info3"
          />
          <h5 class="mb-0 text-primary ml-1">
            Информация по долгу
          </h5>
        </div>

        <div>
          <div v-if="info3Edit">
            <b-button
              variant="success"
              size="sm"
              class="p-50"
              @click="saveUpdateInfo3"
            >
              <feather-icon
                icon="CheckIcon"
              />
            </b-button>
            <b-button
              variant="danger"
              size="sm"
              class="p-50 ml-50"
              @click="info3Edit = !info3Edit"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-button>
          </div>

          <b-button
            v-else
            variant="warning"
            size="sm"
            class="p-50"
            @click="info3Edit = !info3Edit"
          >
            <feather-icon
              icon="Edit3Icon"
            />
          </b-button>
        </div>
      </div>

      <div
        v-if="info3"
        class="mt-2"
      >
        <div class="d_flex">
          <b class="text-danger">Сумма долга:</b>
          <b class="ml-50 text-danger"> {{ $_moneyFormatter(dkDebt.BASIS.DEBT) }} </b>
        </div>
        <div class="d_flex">
          <span>Дата последнего платежа:</span>
          <span
            class="ml-50 text-right"
          > {{
            dkDebt.BASIS.LAST_PAYMENT_DATE_COMPANY ? dkDebt.BASIS.LAST_PAYMENT_DATE_COMPANY : dkDebt.BASIS.PAY_DATE
          }} </span>
        </div>
        <div class="d_flex">
          <span>Сумма последнего платежа:</span>
          <span
            class="ml-50 text-right"
          > {{
            $_moneyFormatter(dkDebt.BASIS.LAST_PAYMENT_DEBT_FOR_COMPANY ? dkDebt.BASIS.LAST_PAYMENT_DEBT_FOR_COMPANY : dkDebt.BASIS.PAY_AMOUNT)
          }}</span>
        </div>
        <div class="d_flex">
          <span>Отказ от взаимодействия с клиентом:</span>
          <span
            v-if="!info3Edit"
            class="ml-50 text-right"
          >
            {{ dkDebt.BASIS.REFUSED_FROM_INTERACTION_WITH_CLIENT === 'Y' ? 'Да' : 'Нет' }}
          </span>
          <VSelect
            v-else
            v-model="dkDebt.BASIS.REFUSED_FROM_INTERACTION_WITH_CLIENT"
            label="title"
            :reduce="p => p.value"
            :options="YesNo"
            style="width: 120px"
          />
        </div>

        <span v-b-toggle.collapse-2>Просрочка
          <feather-icon
            class="text-primary"
            icon="ChevronDownIcon"
          />
        </span>
        <b-collapse
          id="collapse-2"
          class="ml-2"
        >
          <div class="d_flex mt-1">
            <span>Количество просроченных дней:</span>
            <span class="ml-50 text-right"> <b-badge
              variant="light-danger"
            >{{ dkDebt.BASIS.DELAY ? parseInt(dkDebt.BASIS.DELAY) : dkDebt.BASIS.DELAY }}</b-badge> </span>
          </div>
          <div class="d_flex mt-1">
            <span>Количество пролонгаций:</span>
            <span class="ml-50 text-right">{{ dkDebt.BASIS.NO_OF_PROLONGATIONS }}</span>
          </div>
          <div class="d_flex mt-1">
            <span>Сумма займа:</span>
            <span class="ml-50 text-right">{{ $_moneyFormatter(dkDebt.BASIS.DEBT_SUM) }}</span>
          </div>
          <div class="d_flex mt-1">
            <span>Задолженность по процентам:</span>
            <span class="ml-50 text-right">{{ $_moneyFormatter(dkDebt.BASIS.INTEREST_RATE) }}</span>
          </div>
          <div class="d_flex mt-1">
            <span>Неустойка:</span>
            <span class="ml-50 text-right">{{ $_moneyFormatter(dkDebt.BASIS.PENALTY) }}</span>
          </div>
          <div class="d_flex mt-1">
            <span>Штрафы:</span>
            <span class="ml-50 text-right">{{ $_moneyFormatter(dkDebt.BASIS.FEES_DUE) }}</span>
          </div>
          <div class="d_flex mt-1">
            <span>Сумма текущ. задолж. по осн. долгу (в руб.):</span>
            <span class="ml-50 text-right">{{ $_moneyFormatter(dkDebt.BASIS.DEBT_AMOUNT) }}</span>
          </div>
          <div class="d_flex mt-1">
            <span>Сумма начисленных процентов на текущ.осн.долг (в руб.):</span>
            <span class="ml-50 text-right">{{ $_moneyFormatter(dkDebt.BASIS.DEBT_INTEREST) }}</span>
          </div>
          <div class="d_flex mt-1">
            <span>Сумма просрочки осн.долга (в руб.):</span>
            <span class="ml-50 text-right">{{ $_moneyFormatter(dkDebt.BASIS.OVERDUE_AMOUNT) }}</span>
          </div>
          <div class="d_flex mt-1">
            <span>Сумма просрочки по уплате процентов (в руб.):</span>
            <span class="ml-50 text-right">{{ $_moneyFormatter(dkDebt.BASIS.OVERDUE_INTEREST) }}</span>
          </div>
          <div class="d_flex mt-1">
            <span>Пени за просрочку основного долга (в руб.):</span>
            <span class="ml-50 text-right">{{ $_moneyFormatter(dkDebt.BASIS.PENALTY_MAIN) }}</span>
          </div>
          <div class="d_flex mt-1">
            <span>Пени за просрочку процентов (в руб.):</span>
            <span class="ml-50 text-right">{{ $_moneyFormatter(dkDebt.BASIS.PENALTY_INTEREST) }}</span>
          </div>
          <div class="d_flex mt-1">
            <span>Просроченная комиссия за ведение ссудного счета (в руб.):</span>
            <span class="ml-50 text-right">{{ $_moneyFormatter(dkDebt.BASIS.OVERDUE_FEE_LOAN) }}</span>
          </div>
          <div class="d_flex mt-1">
            <span>Сумма задолженности по государственной пошлине (руб.):</span>
            <span class="ml-50 text-right">{{ $_moneyFormatter(dkDebt.BASIS.AMOUNT_DUTY_DEBT) }}</span>
          </div>
          <div class="d_flex mt-1">
            <span>Общая просроченная задолженность к погашению (в руб.):</span>
            <span class="ml-50 text-right">{{ $_moneyFormatter(dkDebt.BASIS.DEBT) }}</span>
          </div>
          <div class="d_flex mt-1">
            <span>Суммарная задолженность (в руб.):</span>
            <span class="ml-50 text-right">{{ $_moneyFormatter(dkDebt.BASIS.SUMARNIY_DEBT) }}</span>
          </div>
        </b-collapse>
      </div>
    </b-card>

    <b-card
      no-body
      class="p-1 mb-1"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="info4 = !info4"
        >
          <buttonCollapse
            :item="info4"
          />
          <h5 class="mb-0 text-primary ml-1">
            Информация по договору
          </h5>
        </div>

        <b-button
          v-b-modal:contractModal
          variant="warning"
          size="sm"
          class="p-50"
        >
          <feather-icon
            icon="Edit3Icon"
          />
        </b-button>
      </div>

      <div
        v-if="info4"
        class="mt-2"
      >
        <div class="d_flex">
          <span>Наименование филиала:</span>
          <span class="ml-50 text-right"> {{ dkDebt.BASIS.BRANCH_NAME }} </span>
        </div>
        <div class="d_flex">
          <span>Номер ссудного счета (20-ти значный):</span>
          <span class="ml-50 text-right"> {{ dkDebt.BASIS.CREDIT_ACCOUNT }}</span>
        </div>
        <div class="d_flex">
          <span>Наименование вида кредита:</span>
          <span class="ml-50 text-right"> {{ dkDebt.BASIS.TYPE_CREDIT }} </span>
        </div>
        <div class="d_flex">
          <span>Номер кредитного договора:</span>
          <span class="ml-50 "> {{ dkDebt.BASIS.DEBT_TYPE }} </span>
        </div>
        <div class="d_flex">
          <span>Дата выдачи:</span>
          <span class="ml-50 text-right">{{ dkDebt.BASIS.DEBT_START_DATE }}</span>
        </div>
        <div class="d_flex mt-1">
          <span>Дата возврата:</span>
          <span class="ml-50 text-right">{{ dkDebt.BASIS.DEBT_END_DATE }}</span>
        </div>
        <div class="d_flex mt-1">
          <span class="text-left">Дата образования просроченной задолженности:</span>
          <span class="ml-50 text-right">{{ dkDebt.BASIS.DATE_DEBT_FORMATION }}</span>
        </div>
        <div class="d_flex mt-1">
          <span>Дата передачи:</span>
          <span class="ml-50 text-right">{{ dkDebt.BASIS.SENT_DATE }}</span>
        </div>
        <div class="d_flex mt-1">
          <span>Максимальная дата размещения:</span>
          <span class="ml-50 text-right">{{ dkDebt.BASIS.PAYMENT_END_OF_PERIOD }}</span>
        </div>
        <div class="d_flex mt-1 w-100">
          <span class="text-left w-50">
            Признак обеспечения по договору/
            Расшифровка признака обеспечения:
          </span>
          <span class="ml-50 text-right w-50">
            {{ dkDebt.BASIS.SIGN_CONTRACT }}
          </span>
        </div>
        <div class="d_flex mt-1 ">
          <span>Цессия :</span>
          <span class="ml-50 text-right">
            {{ dkDebt.BASIS.SESSION }}
          </span>
        </div>
        <div class="d_flex mt-1 ">
          <span>Арест на счет :</span>
          <span class="ml-50 text-right">
            <b-form-checkbox
              disabled
              :checked="dkDebt.BASIS.AREST"
            />
          </span>
        </div>
        <div class="d_flex mt-1 ">
          <span>Прочая информация :</span>
          <span class="ml-50 text-right">{{ dkDebt.BASIS.ALL_FLAGS === 'None' ? '' : dkDebt.BASIS.ALL_FLAGS }}</span>
        </div>
      </div>
    </b-card>

    <b-card
      no-body
      class="p-1 mb-1"
      indicators
    >
      <div class="d_flex mb-0">
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="guarantor = !guarantor"
        >
          <buttonCollapse
            :item="guarantor"
          />
          <h5 class="mb-0 ml-1 text-primary">
            Поручитель
          </h5>
        </div>

        <b-button
          v-b-modal:guarantor
          variant="warning"
          size="sm"
          class="p-50"
        >
          <feather-icon
            icon="Edit3Icon"
          />
        </b-button>
      </div>
      <b-carousel
        v-if="guarantor"
        id="carousel-1"
        ref="myCarousel"
        :interval="0"
        indicators
        class="mt-2"
      >
        <p
          v-if="dkDebt.GUARANTOR && dkDebt.GUARANTOR.length === 0"
          class="text-center"
        >
          Нет данных
        </p>
        <b-carousel-slide
          v-for="(item, index) in dkDebt.GUARANTOR"
          :key="index"
          class="carousel-inner"
        >
          <template #img>
            <div class="d-flex justify-content-between mb-1">
              <span class="text-left">Ф.И.О.</span>
              <span class="text-right">{{ item.FULL_NAME }}</span>
            </div>
            <div class="d-flex justify-content-between mb-1">
              <span class="text-left">Номер телефона.</span>
              <span class="text-right">{{ item.INCOME_SOURCE_PHONE }}</span>
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>

      <div
        v-if="guarantor && dkDebt.GUARANTOR.length"
        class="d-flex align-items-center justify-content-between"
      >
        <div
          class="p-50 cursor-pointer"
          @click="prev"
        >
          <feather-icon
            size="20"
            icon="ChevronLeftIcon"
            class="text-primary"
          />
        </div>

        <div
          class="p-50 cursor-pointer"
          @click="next"
        >
          <feather-icon
            size="20"
            icon="ChevronRightIcon"
            class="text-primary"
          />
        </div>
      </div>
    </b-card>

    <!--modal-->
    <debtorModal />

    <debtInfo />

    <contactInformation />

    <guarantorModal
      :guarantor-arr="dkDebt.GUARANTOR"
    />
    <!--modal-->

    <TheDebtorIncomeModal
      ref="incomeModal"
      :add-btn="false"
      @workModal="workModal"
    />

    <!--    Имущество-->
    <PropertyModal @propertyRefresh="propertyRefresh" />
  </div>
</template>

<script>
import {
  BButton, BCard, BCarousel, BCarouselSlide, BCollapse, BBadge, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import buttonCollapse from '@/components/buttonCollapse.vue'
import TheDebtorIncomeModal from '@/modules/statementDebtor/components/TheDebtorIncomeModal.vue'
import PropertyModal from '@/modules/collectorDetail/components/AddEditModal/PropertyModal.vue'
import VSelect from 'vue-select'
import debtInfo from '../AddEditModal/debtInfo.vue'
import guarantorModal from '../AddEditModal/guarantor.vue'
import debtorModal from '../AddEditModal/debtor.vue'
import contactInformation from '../AddEditModal/contactInformation.vue'

export default {
  components: {
    PropertyModal,
    TheDebtorIncomeModal,
    BCard,
    BButton,
    BCarousel,
    BCarouselSlide,
    BCollapse,
    BBadge,
    BOverlay,
    BFormCheckbox,
    buttonCollapse,
    debtInfo,
    guarantorModal,
    debtorModal,
    contactInformation,
    VSelect,
  },
  data() {
    return {
      info: true,
      info2: true,
      info3: true,
      info4: false,
      guarantor: false,
      YesNo: [
        {
          title: 'Да',
          value: 'Y',
        },
        {
          title: 'Нет',
          value: 'N',
        },
      ],
      info3Edit: false,
      busy: false,
    }
  },
  computed: {
    ...mapState('collectorDetail', ['dkDebt']),

    formattedTimeZoneOffset() {
      const offset = this.dkDebt.BORROWER.TIME_ZONE_UTC
      const sign = offset >= 0 ? '+' : '-'
      const hours = Math.abs(Math.floor(offset))
      const minutes = Math.abs((offset % 1) * 60)

      const formattedHours = hours.toString().padStart(2, '0')
      const formattedMinutes = minutes.toString().padStart(2, '0')

      return `${sign}${formattedHours}:${formattedMinutes}`
    },
  },
  methods: {
    ...mapActions('cases', ['FETCH_SK_STATEMENT_OF_DEBT', 'FETCH_DEBT_PROPERTY_MODAL']),
    ...mapActions('collectorDetail', ['UPDATE_BASIS_BASIS', 'FETCH_DK_DEBT_ID', 'UPDATE_SKSETCONTACT']),

    openModalProperty() {
      this.$bvModal.show('propertyModal')
      this.propertyRefresh()
    },
    propertyRefresh(data) {
      this.FETCH_DEBT_PROPERTY_MODAL({
        id: this.dkDebt.BORROWER.id,
        ...data,
      })
    },
    prev() {
      this.$refs.myCarousel.prev()
    },
    next() {
      this.$refs.myCarousel.next()
    },
    openIncomeModal() {
      this.$refs.incomeModal.open()
      this.workModal()
    },
    workModal(data) {
      this.FETCH_SK_STATEMENT_OF_DEBT({
        id: this.dkDebt.BORROWER.id,
        contact_id: this.dkDebt.BORROWER.id,
        ...data,
      })
    },
    desiredVariant(data) {
      const parts = data.split(',')
      const filteredParts = parts.filter(part => part.trim() !== '')
      return filteredParts.join(' ')
    },

    saveUpdateInfo3() {
      const {
        REFUSED_FROM_INTERACTION_WITH_CLIENT,
        id,
      } = this.dkDebt.BASIS
      this.UPDATE_BASIS_BASIS({
        id,
        data: {
          REFUSED_FROM_INTERACTION_WITH_CLIENT,
        },
      })
        .then(res => {
          if (res.data && res.data.REFUSED_FROM_INTERACTION_WITH_CLIENT === 'Y') {
            this.$router.push('/portfolios/')
          } else {
            this.info3Edit = false
          }
          this.$_okToast()
        })
        .catch(e => this.$_errorToast(e))
    },

    updateDie() {
      this.$_showAreYouDie()
        .then(res => {
          if (res) {
            this.busy = true
            const {
              IS_DEAD,
              id,
            } = this.dkDebt.BORROWER

            const newData2 = {
              id,
              data: {},
            }

            if (IS_DEAD === false) {
              newData2.data.IS_DEAD = true
            } else if (IS_DEAD === true) {
              newData2.data.IS_DEAD = false
            } else if (IS_DEAD === '' || IS_DEAD === 'None' || IS_DEAD === null) {
              newData2.data.IS_DEAD = true
            }

            this.UPDATE_SKSETCONTACT(newData2)
              .then(() => {
                this.$_okToast()
                this.FETCH_DK_DEBT_ID({ id: this.$route.params.id })
                this.busy = false
              })
              .catch(e => {
                this.busy = false
                this.$_errorToast(e)
              })
          }
        })
    },

    update3RD() {
      this.$_showAreYouSureYesNo()
        .then(res => {
          if (res) {
            this.busy = true
            const {
              REFUSED_FROM_3RD_PARTIES,
              id,
            } = this.dkDebt.BASIS

            const newData = {
              id,
              data: {},
            }

            if (REFUSED_FROM_3RD_PARTIES === 'N') {
              newData.data.REFUSED_FROM_3RD_PARTIES = 'Y'
            } else if (REFUSED_FROM_3RD_PARTIES === 'Y') {
              newData.data.REFUSED_FROM_3RD_PARTIES = 'N'
            } else if (REFUSED_FROM_3RD_PARTIES === '' || REFUSED_FROM_3RD_PARTIES === 'None' || REFUSED_FROM_3RD_PARTIES === null) {
              newData.data.REFUSED_FROM_3RD_PARTIES = 'Y'
            }

            this.UPDATE_BASIS_BASIS(newData)
              .then(() => {
                this.$_okToast()
                this.FETCH_DK_DEBT_ID({ id: this.$route.params.id })
                this.busy = false
              })
              .catch(e => {
                this.busy = false
                this.$_errorToast(e)
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
.d_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.client_info {
  .carousel-indicators {
    bottom: -45px;

    li {
      border-radius: 100%;
      height: 8px;
      width: 8px;
      background-color: #3646af;
    }
  }
}
</style>
