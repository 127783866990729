<template>
  <b-modal
    id="debtor"
    title="Должник"
    no-close-on-backdrop
    centered
    size="lg"
    cancel-title="Отмена"
    ok-title="Сохранить"
    @show="openShowModal"
    @ok.prevent="onSubmit"
  >
    <b-row>
      <b-col md="12">
        <b-form-group label="Адрес:">
          <b-form-input v-model="data.address" />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group label="Сайт:">
          <b-form-input v-model="data.web_site" />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group label="Телефон:">
          <b-form-input v-model="data.phone_number" />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
  },
  data() {
    return {
      data: {
        address: null,
        web_site: null,
        phone_number: null,
      },
    }
  },
  computed: {
    ...mapState('collectorDetail', ['dkDebt']),
  },
  methods: {
    ...mapActions('collectorDetail', ['UPDATE_CLAIMER_COMPANY', 'FETCH_DK_DEBT_ID']),

    onSubmit() {
      const { COMPANY_ID } = this.dkDebt.CLAIMER_CRM_COMPANY_ID
      const { data } = this

      this.UPDATE_CLAIMER_COMPANY({ id: COMPANY_ID, data })
        .then(() => {
          this.$bvModal.hide('debtor')
          this.FETCH_DK_DEBT_ID({ id: this.$route.params.id })
          this.$_okToast()
        })
        .catch(e => this.$_errorToast(e))
    },

    openShowModal() {
      const { address, web_site, phone_number } = this.dkDebt.CLAIMER_CRM_COMPANY_ID

      this.data = {
        address,
        web_site,
        phone_number,
      }
    },
  },
}
</script>
