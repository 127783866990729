<template>
  <div>
    <b-row>
      <!-------------------history timeline--------------->
      <b-col md="6">
        <b-card no-body class="time__med">
          <div class="position-relative">
            <b-tabs class="w-100">
              <b-tab active title="История статуса">
                <div class="d_flex mb-0">
                  <div
                    class="d-flex align-items-center cursor-pointer justify-content-between w-100"
                  >
                    <!--              <h5 class="mb-0 text-primary">-->
                    <!--                История статуса-->
                    <!--              </h5>-->
                  </div>
                </div>
                <div style="height: 213px" class="overflow-auto">
                  <app-timeline class="mt-2 position-relative pr-1">
                    <LazyScroll
                      :other-params="{
                        page_size: 5,
                      }"
                      :store-fetch="getFunc"
                      :store-list="historyTimeLine"
                    >
                      <app-timeline-item
                        v-for="(e, inx) in historyTimeLine.results"
                        :key="inx"
                        class="pb-2"
                        :variant="
                          e.status === 'light-danger' ? 'light_dann' : e.status
                        "
                      >
                        <div class="d-flex justify-content-between">
                          <div class="mb-sm-0">
                            <h6 class="mb-0">
                              {{ textHistory(e.status) }}
                            </h6>
                          </div>
                          <div class="d-flex flex-column flex-wrap text-right">
                            <small class="mb-0 text-dark">
                              {{ e.created_date }}
                            </small>
                            <small
                              v-if="e.user"
                              class="timeline-item-time text-nowrap text-dark"
                            >
                              {{ e.user.full_name }}
                            </small>
                          </div>
                        </div>
                      </app-timeline-item>
                    </LazyScroll>

                    <span
                      v-if="historyTimeLine.count === 0"
                      class="w-100 d-block text-center"
                    >
                      Нет данных
                    </span>
                  </app-timeline>
                </div>
              </b-tab>
              <b-tab :active="activeTab" title="Список напоминаний" lazy>
                <ReminderList />
              </b-tab>
            </b-tabs>

            <div
              style="position: absolute; top: 0; right: 0"
              class="d-flex align-items-center"
            >
              <b-badge
                v-if="!dkDebt.IS_ACTIVE"
                variant="light-danger"
                class="mr-50"
              >
                Исключен
              </b-badge>
              <b-button
                v-if="getUserData.permissions.includes('admin')"
                class="dolga"
                :disabled="dkDebt.IS_CLOSED || disabled"
                :variant="
                  dkDebt.IS_CLOSED || disabled ? 'secondary' : 'success'
                "
                @click="closeDebt"
              >
                {{ dkDebt.IS_CLOSED || disabled ? "Оплачен" : "Долг оплачен" }}
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
      <!-------------------history timeline--------------->

      <!-------------------active card tabs--------------->
      <b-col md="3" class="vfor__cards">
        <b-card
          v-for="(item, index) in cards"
          :key="index"
          :class="active === item.active ? 'acts2 acts' : 'acts'"
          no-body
          @click="getActive(item.active)"
        >
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon class="text-primary" :icon="item.icon" size="16" />
            <h5 class="mb-0 ml-50">
              {{ item.title }}
            </h5>
          </div>
        </b-card>

        <b-card
          v-b-toggle.sidebar-comment
          :class="'acts'"
          no-body
          @click="openComment"
        >
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon
              class="text-primary"
              :icon="'MessageSquareIcon'"
              size="16"
            />
            <h5 class="mb-0 ml-50">Комментарии</h5>
          </div>
        </b-card>
      </b-col>

      <!--      //////buttons-->
      <b-col md="3">
        <!-------------------statistic cards--------------->
        <statisticCards :active="active" @getActive="getActive" />
        <!-------------------statistic cards--------------->
        <!--        class="three__btn"-->
        <b-card
          no-body
          :class="call ? 'assaaa mb-1' : ' mb-1 transition__assa'"
          class="cursor-pointer"
          style="padding: 17px 12px"
          :bg-variant="call ? 'warning' : 'success'"
          @click="openCallTab"
        >
          <div class="d-flex align-items-center justify-content-between">
            <feather-icon icon="PhoneCallIcon" class="text-white ml-75" />
            <div style="width: 90%" class="text-center">
              <span class="fs-13 text-white"> Позвонить </span>
            </div>
          </div>
        </b-card>
      </b-col>
      <!-------------------active card tabs--------------->

      <!-------------------active modules--------------->
      <b-col md="12">
        <!--        pozvanit table-->
        <callTable v-if="call" />

        <otherMatters v-if="active === 1" />

        <attachedDocs v-if="active === 2" />

        <promiseTable v-if="active === 3" />

        <!--        header statistics-->
        <manualCll v-if="active === 4" />

        <autoCallPage v-if="active === 6" />

        <manualSmsPage v-if="active === 7" />

        <autoSmsPage v-if="active === 8" />

        <!--        header statistics-->

        <paymentHistory v-if="active === 5" />
      </b-col>
      <!-------------------active modules--------------->
    </b-row>

    <autoCall />
    <manualSms />

    <comment />
  </div>
</template>

<script>
import { BCard, BCol, BRow, BButton, BTab, BTabs, BBadge } from "bootstrap-vue";
import { mapActions, mapMutations, mapState } from "vuex";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import otherMatters from "@/modules/collectorDetail/components/collectorDetail/activeModules/otherMatters.vue";
import attachedDocs from "@/modules/collectorDetail/components/collectorDetail/activeModules/attachedDocs/attachedDocs.vue";
import moment from "moment";
import LazyScroll from "@components/LazyScroll/LazyScroll.vue";
import autoSmsPage from "@/modules/collectorDetail/components/collectorDetail/statistics/autoSms.vue";
import autoCallPage from "@/modules/collectorDetail/components/collectorDetail/statistics/autoCll.vue";
import manualSmsPage from "@/modules/collectorDetail/components/collectorDetail/statistics/manuaSms.vue";
import manualCll from "@/modules/collectorDetail/components/collectorDetail/statistics/manualCll.vue";
import ReminderList from "./reminderList.vue";
import statisticCards from "./statisticCards.vue";
import comment from "./activeModules/comment.vue";
import callTable from "./buttonsModal/call.vue";
import paymentHistory from "./activeModules/paymentHistory.vue";
import promiseTable from "./activeModules/promises.vue";
import autoCall from "./buttonsModal/autoCall.vue";
import manualSms from "./buttonsModal/manualSms.vue";

export default {
  components: {
    manualCll,
    manualSmsPage,
    autoCallPage,
    autoSmsPage,
    LazyScroll,
    BRow,
    BCol,
    BCard,
    BButton,
    BTab,
    BTabs,
    statisticCards,
    AppTimeline,
    AppTimelineItem,
    comment,
    callTable,
    paymentHistory,
    otherMatters,
    attachedDocs,
    promiseTable,
    autoCall,
    manualSms,
    ReminderList,
    BBadge,
  },
  data() {
    return {
      call1: false,
      disabled: false,
    };
  },
  computed: {
    ...mapState("collectorDetail", [
      "cards",
      "history",
      "dkDebt",
      "active",
      "historyTimeLine",
      "call",
      "activeTab",
    ]),

    moment() {
      return moment;
    },

    getUserData() {
      return JSON.parse(localStorage.getItem("userData"));
    },
  },
  async mounted() {
    const params = {
      page_size: 5,
      debt: this.$route.params.id,
      page: 1,
    };
    await this.FETCH_HISTORY_TIMELINE(params);
    await this.FETCH_SK_HEADER_STA({ debt_id: this.$route.params.id });
  },
  methods: {
    ...mapMutations("collectorDetail", [
      "SET_ACTIVE",
      "SET_CALL",
      "SET_COMMIT_EMPTY",
    ]),
    ...mapActions("collectorDetail", [
      "FETCH_DK_DEBT_OTHER",
      "FETCH_HISTORY_TIMELINE",
      "FETCH_SK_HEADER_STA",
      "FETCH_SK_CALL_STATUS",
      "FETCH_UTILS_COMMENT1",
      "SEND_CLOSE_DEBT",
    ]),

    closeDebt() {
      this.$bvModal
        .msgBoxConfirm("Вы действительно хотите закрыть долг?.", {
          title: "Вы действительно хотите ?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Подтвердить",
          cancelTitle: "Отменить",
          cancelVariant: "secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((res) => {
          if (res) {
            this.SEND_CLOSE_DEBT({ debt_id: this.$route.params.id })
              .then(() => {
                this.dkDebt.IS_CLOSED = !this.dkDebt.IS_CLOSED;
                this.disabled = true;
              })
              .catch((e) => {
                this.$_errorToast(e);
              });
          }
        });
    },

    openComment() {
      this.SET_COMMIT_EMPTY();
      this.FETCH_UTILS_COMMENT1({
        content_type: this.dkDebt.CONTENT_TYPE,
        object_id: this.dkDebt.id,
        page: 1,
        page_size: 15,
      });
    },

    async getFunc() {
      const params = {
        page_size: 5,
        debt: this.$route.params.id,
        page: this.historyTimeLine.page === 1 ? 1 : this.historyTimeLine.next,
      };
      await this.FETCH_HISTORY_TIMELINE(params);
    },

    textHistory(text) {
      const messages = {
        secondary: "Неправильный номер",
        primary: "Связь прервалась",
        "light-danger": "Отказ",
        dark: "Банкрот",
        danger: "Неперспективный",
        info: "Не ответил",
        warning: "Обещание",
      };
      return messages[text] || "";
    },

    getActive(active) {
      this.SET_ACTIVE(active);
      this.SET_CALL(false);
    },

    openCallTab() {
      if (this.time) {
        cancelAnimationFrame(this.time);
      }

      const newCall = !this.call;

      this.SET_CALL(newCall);

      if (newCall) {
        this.time = requestAnimationFrame(() => {
          this.FETCH_SK_CALL_STATUS({ debt_id: this.$route.params.id });
          this.FETCH_DK_DEBT_OTHER({
            BORROWER: this.dkDebt.BORROWER.id,
            page_size: 5,
            id: this.$route.params.id,
          });
        });
      }
    },
  },
};
</script>
<style lang="scss">
.acts {
  padding: 13px;
  margin-bottom: 14px;
  cursor: pointer;
}

.acts2 {
  background-color: #147bfc !important;

  h5 {
    color: white;
  }

  svg {
    fill: #fff;
  }
}

.dark-layout {
  svg {
    fill: transparent;
  }
}

.assaaa {
  transform: scale(0.945) !important;
  transition: all 0.3s;
}

.transition__assa {
  transition: all 0.3s;
}

.timeline-variant-light_dann .timeline-item-point {
  background-color: rgba(240, 129, 130, 0.68) !important;
}

.timeline-variant-light_dann .timeline-item-point:before {
  background-color: rgba(240, 129, 130, 0.24) !important;
}

.timeline-variant-purple .timeline-item-point {
  background-color: #9452ff !important;
}

.timeline-variant-purple .timeline-item-point:before {
  background-color: rgba(148, 82, 255, 0.22) !important;
}

.paddinggg {
  padding: 7px 12px;
}
</style>
