<template>
  <b-overlay
    :show="show"
    rounded="sm"
  >
    <b-card>
      <div class="d-flex align-items-center justify-content-between mb-1">
        <div class="d-flex align-items-center">
          <h4 class="mb-0">
            Позвонить
          </h4>
          <b-button
            variant="warning"
            size="sm"
            class="ml-1 asasa text-dark"
            @click="$bvModal.show('reminderModal')"
          >
            <svg
              class="mr-50"
              height="20px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 47.5 56.44"
            >
              <g>
                <g>
                  <path
                    d="M23.75,56.44a7.81,7.81,0,0,0,7.82-7.77H15.91a7.83,7.83,0,0,0,7.84,7.77Z"
                    class="svg-bell svg-bell_clapper"
                  />
                  <path
                    d="M41.9,38.72V23.52a17.83,17.83,0,0,0-14-17.43V4.15a4.19,4.19,0,0,0-8.38,0V6.09a17.84,17.84,0,0,0-14,17.43v15.2L0,44.26V47H47.5V44.26Z"
                    class="svg-bell svg-bell_whole"
                  />
                </g>
              </g>
            </svg>
            Добавить напоминание
          </b-button>
        </div>
        <div class="d-flex align-items-center">
          <a
            class="d-flex align-items-center justify-content-end"
            :href="'https://wa.me/' + `${defaultNum}`"
            target="_blank"
          >
            <!--          :disabled="getDisabledUp"-->
            <b-button
              variant="success"
              size="sm"
              class="p-25 btn_disable"
            >
              <img
                height="30"
                :src="require('@/assets/whatsapp.png')"
              >
              <span class="align-middle text-white mr-75">
                {{ defaultNum.length === 11 ? formattedPhoneNumber(defaultNum) : 'Недействительно' }}
              </span>
            </b-button>
          </a>

          <a
            class="d-flex align-items-center justify-content-end ml-1"
            :href="'tel:' + `${defaultNum}` + '*58'"
          >
            <b-button
              :disabled="getDisabledUp"
              variant="warning"
              class="btn_disable"
              @click="callHistory"
            >
              <feather-icon
                class="mr-50 text-white"
                icon="phoneIcon"
              />
              <span class="align-middle text-white">
                {{ defaultNum.length === 11 ? formattedPhoneNumber(defaultNum) : 'Недействительно' }}
              </span>
            </b-button>
          </a>

          <b-button
            :disabled="dkDebt.BORROWER.contact_phones === 0 || getDisabled"
            variant="primary"
            class="ml-1"
            @click="closeTable"
          >
            <feather-icon :icon="numbersMenu ? 'XIcon' : 'ListIcon'" />
          </b-button>
        </div>
      </div>

      <div class="position-relative">
        <b-table
          class="table-bordered"
          :fields="columns"
          :items="formatedItems"
          show-empty
          empty-text="Нет данных"
        >
          <template #cell(branch_name)="props">
            <flat-pickr
              v-model="props.item.promised_date"
              style="border: 1px solid blue;"
              :config="configdateTimePicker"
              placeholder="Обещанная дата"
              class="form-control bg-white"
            />

            <b-form-input
              v-model="props.item.promised_amount"
              style="    border: 1px solid blue;"
              class="mt-1"
              placeholder="Обещанная сумма"
              type="number"
            />

            <div
              v-if="dkDebt.CLAIMER_CRM_COMPANY_ID.status_extra"
              class="d-flex flex-column flex-wrap mt-1 radiosss"
            >
              <b-form-radio
                v-model="promise_type"
                class="mb-50"
                name="some-radios"
                value="SETTLEMENT_AGREEMENT"
              >
                Мировое соглашение
              </b-form-radio>
              <b-form-radio
                v-model="promise_type"
                class="mb-50"
                name="some-radios"
                value="ASSIGNMENT"
              >
                Цессия
              </b-form-radio>
              <b-form-radio
                v-model="promise_type"
                name="some-radios"
                value="WRITE_PENALTIES"
              >
                Списание пени
              </b-form-radio>
            </div>
          </template>
          <template #cell(pay_his)="props">
            <div v-if="props.item.BASIS">
              <div
                v-for="(e, index) in props.item.BASIS.PAY_HISTORY"
                :key="index"
              >
                <ul
                  class="d-flex align-items-center justify-content-between mb-0"
                >
                  <li style="font-size: 11px">
                    {{ e.PAY_DATE }}
                  </li>
                  <small class="ml-50">
                    {{ $_moneyFormatter(e.PAY_AMOUNT) }},
                  </small>
                </ul>
              </div>
            </div>
          </template>

          <template #cell(SUM)="props">
            <b v-if="props.item.BASIS && props.item.BASIS.DEBT">
              {{ $_moneyFormatter(props.item.BASIS.DEBT) }}
            </b>
          </template>

          <template #cell(debtors)="data">
            <div>
              {{ data.item.BORROWER.FULL_NAME }}
            </div>
          </template>

          <template #cell(BASIS)="props">
            <div v-if="props.item.BASIS">
              <span v-if="props.item.BASIS.DO_STAGE === 'voluntary_payment'">Добровольная оплата</span>
              <span v-else-if="props.item.BASIS.DO_STAGE === 'claim'">Претензия</span>
              <span v-if="props.item.BASIS.DO_STAGE === 'judical_work'">Судебная работа</span>
              <span v-else-if="props.item.BASIS.DO_STAGE === 'executive_document'">Исполнительный документ</span>
              <span v-if="props.item.BASIS.DO_STAGE === 'executive_work'">Исполнительное производство</span>
              <span v-else-if="props.item.BASIS.DO_STAGE === 'stopped'">Приостановлено</span>
              <span v-if="props.item.BASIS.DO_STAGE === 'paid'">Погашен</span>
            </div>
          </template>
        </b-table>

        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap mr-1">Показать по</span>
            <b-form-select
              v-model="params.page_size"
              :options="['5', '10', '15']"
              class="mx-1"
              @change="changePerPage"
            />
            <span class="ml-1 text-nowrap"> строк( {{ dkDebtOther.count }} )</span>
          </div>
          <div>
            <b-pagination
              v-model="params.page"
              :total-rows="dkDebtOther.count"
              :per-page="params.page_size"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>

        <div class="text-right">
          <div class="mt-1 d-flex flex-wrap  align-items-center justify-content-end call__btn">
            <b-button
              :disabled="getDisabled"
              class="mr-1 mb-1 btn text-white"
              variant="dark"
              @click="historyTimeLine('dark')"
            >
              Банкрот
            </b-button>

            <b-button
              :disabled="getDisabled"
              class="mr-1 mb-1"
              variant="secondary"
              @click="historyTimeLine('secondary')"
            >
              Неправильный номер
            </b-button>

            <b-button
              :disabled="getDisabled"
              class="mr-1 mb-1"
              variant="primary"
              @click="historyTimeLine('primary')"
            >
              Связь прервалась
            </b-button>

            <b-button
              :disabled="getDisabled"
              class="bg-lighten-2 bg-danger border-danger mr-1 mb-1"
              @click="historyTimeLine('light-danger')"
            >
              Отказ
            </b-button>

            <b-button
              :disabled="getDisabled"
              class="mr-1 mb-1"
              variant="danger"
              @click="historyTimeLine('danger')"
            >
              Неперспективный
            </b-button>

            <b-button
              :disabled="getDisabled"
              class="mr-1 mb-1"
              variant="info"
              @click="historyTimeLine('info')"
            >
              Не ответил
            </b-button>

            <b-button
              :disabled="getDisabled"
              class="mb-1"
              variant="warning"
              @click="onSubmit"
            >
              Обещание
            </b-button>

          </div>
        </div>

        <!--  phone number list menu-->
        <div
          v-if="numbersMenu"
          id="numMenu"
        >
          <div style="height: 340px; overflow: auto;">
            <table>
              <thead>
                <tr>
                  <th scope="col">
                    Тип контакт
                  </th>
                  <th scope="col">
                    Телефон
                  </th>
                  <th scope="col">
                    Статус
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in dkDebt.BORROWER && dkDebt.BORROWER.contact_phones"
                  :key="index"
                  @click="getNumber(item)"
                >
                  <td>
                    {{ item.TYPE_CONTACT }}
                  </td>
                  <td>
                    {{ item.PHONE }}
                  </td>
                  <td @click.stop>
                    <b-badge
                      size="sm"
                      class="cursor-pointer"
                      :variant="item.IS_ACTIVE ? 'success' : 'danger'"
                      @click.stop="updateStatus(item)"
                    >
                      {{ item.IS_ACTIVE ? 'Активный' : 'Не активный' }}
                    </b-badge>
                  </td>
                </tr>
                <tr v-if="isCreate">
                  <td>
                    <select
                      v-model="forms.TYPE_CONTACT"
                      class="form-control rounded outline-none"
                    >
                      <option
                        v-for="(val, index2) in status"
                        :key="index2"
                        :value="val.value"
                      >
                        {{ val.title }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <b-form-input
                      v-model="forms.PHONE"
                      type="number"
                    />
                  </td>
                  <td>
                    <b-button
                      :disabled="!forms.PHONE || !forms.TYPE_CONTACT"
                      variant="success"
                      size="sm"
                      class="p-25"
                      @click="createPhone"
                    >
                      <feather-icon icon="CheckIcon" />
                    </b-button>
                    <b-button
                      variant="danger"
                      size="sm"
                      class="p-25 ml-50"
                      @click="cancelCreate"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </td>
                </tr>
                <tr v-if="plusBtn">
                  <td
                    colspan="3"
                    class="p-0"
                  >
                    <button
                      class="w-100 p-0 border-0 btn-success text-white"
                      size="sm"
                      @click="addPhoneNumber"
                    >
                      <feather-icon icon="PlusIcon" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--  phone number list menu-->
      </div>
    </b-card>

    <RemiderModal />
  </b-overlay>
</template>

<script>
import {
  BTable,
  BFormRadio,
  BFormInput,
  BButton,
  BCard,
  BPagination,
  BFormSelect,
  BOverlay, BBadge,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/extensions
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import { mapActions, mapMutations, mapState } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RemiderModal from './remiderModal.vue'

export default {
  components: {
    BBadge,
    BTable,
    BFormInput,
    BButton,
    BCard,
    flatPickr,
    BPagination,
    BFormSelect,
    BOverlay,
    BFormRadio,
    RemiderModal,
  },
  data() {
    return {
      isCreate: false,
      plusBtn: true,
      status: [
        {
          title: 'Рабочий телефон',
          value: 'Рабочий телефон',
        },
        {
          title: 'Мобильный телефон',
          value: 'Мобильный телефон',
        },
        {
          title: 'Домашний телефон',
          value: 'Домашний телефон',
        },
        {
          title: 'Дополнительный телефон',
          value: 'Дополнительный телефон',
        },
      ],
      forms: {
        PHONE: null,
        TYPE_CONTACT: null,
      },
      numbersMenu: false,
      defaultNum: 0,
      columns: [
        {
          label: 'Обещания',
          key: 'branch_name',
        },
        {
          key: 'pay_his',
          label: 'Последние платежи',
        },
        {
          key: 'LAST_ACTION_DATE',
          label: 'ДАТА ПОСЛЕДНЕГО ДЕЙСТВИЯ',
        },
        {
          key: 'debtors',
          label: 'ФИО ДОЛЖНИКА',
        },
        {
          key: 'SUM',
          label: 'Сумма долга',
        },
        {
          key: 'BASIS',
          label: 'Стадия',
        },
      ],
      params: {
        page_size: 5,
        page: 1,
      },
      configdateTimePicker: {
        dateFormat: 'd.m.Y',
        locale: Russian,
        minDate: 'today',
        maxDate: new Date().fp_incr(14),
      },
      disButton: false,
      disButtonUp: false,
      show: false,
      promise_type: null,
    }
  },
  computed: {
    ...mapState('collectorDetail', ['dkDebtOther', 'dkDebt', 'skCallStatus']),

    formatedItems() {
      if (!this.dkDebtOther.results) return []
      return this.dkDebtOther.results.map(item => {
        item._rowVariant = item.COLOR
        return item
      })
    },

    getUserId() {
      const { id } = useJwt.getUserData()
      return id
    },

    getDisabled() {
      return !!(this.skCallStatus.DEBT_STATUS || this.disButton)
    },

    getDisabledUp() {
      return !!(this.skCallStatus.DEBT_STATUS || this.disButtonUp)
    },
  },
  mounted() {
    this.FETCH_DK_DEBT_ID({ id: this.$route.params.id })
      .then(() => {
        this.setDefaultNumber()
      })
  },
  methods: {
    ...mapActions('collectorDetail', [
      'CREATE_PROMISE', 'CREATE_HISTORY', 'FETCH_HISTORY_TIMELINE', 'FETCH_DK_DEBT_OTHER', 'CREATE_CALL_HISTORY',
      'FETCH_SK_HEADER_STA', 'FETCH_PROMISE_LIST', 'FETCH_DK_DEBT_ID', 'CREATE_DKCONTACT', 'UPDATE_DKCONTACT',
    ]),
    ...mapMutations('collectorDetail', ['SET_EMPTY']),

    closeTable() {
      this.numbersMenu = !this.numbersMenu
      this.isCreate = false
      this.plusBtn = true
    },

    addPhoneNumber() {
      this.isCreate = true
      this.plusBtn = false
    },

    createPhone() {
      const res = {
        IS_ACTIVE: true,
        CONTACT: this.dkDebt.BORROWER.id,
        ...this.forms,
      }

      this.CREATE_DKCONTACT(res)
        .then(res1 => {
          if (res1 && res1.data) {
            this.dkDebt.BORROWER.contact_phones.push(res1.data)
            this.isCreate = false
            this.plusBtn = true
            this.forms = {
              PHONE: null,
              TYPE_CONTACT: null,
            }
          }
        })
    },

    cancelCreate() {
      this.isCreate = false
      this.plusBtn = true
      this.forms = {
        PHONE: null,
        TYPE_CONTACT: null,
      }
    },

    updateStatus(value) {
      const res = {
        IS_ACTIVE: !value.IS_ACTIVE,
        id: value.id,
      }
      this.UPDATE_DKCONTACT(res)
        .then(() => {
          value.IS_ACTIVE = !value.IS_ACTIVE
        })
    },

    setDefaultNumber() {
      const cleanPhoneNumber = phone => phone.replace(/[^0-9]/g, '')

      this.dkDebt.BORROWER && this.dkDebt.BORROWER.contact_phones.forEach(e => {
        if (e.TYPE_CONTACT === 'Рабочий телефон') {
          this.defaultNum = cleanPhoneNumber(e.PHONE)
        }
      })

      if (!this.defaultNum) {
        this.dkDebt.BORROWER && this.dkDebt.BORROWER.contact_phones.forEach(e => {
          if (e.TYPE_CONTACT !== 'Рабочий телефон') {
            this.defaultNum = cleanPhoneNumber(e.PHONE)
          }
        })
      }
    },

    getNumber(item) {
      const cleanPhoneNumber = phone => phone.replace(/[^0-9]/g, '')

      this.defaultNum = cleanPhoneNumber(item.PHONE)
      this.numbersMenu = !this.numbersMenu
    },

    callHistory() {
      const req = {
        call_type: 'DEBTOR_INDIVIDUAL',
        phone: this.defaultNum.length === 11 ? this.defaultNum : null,
        debt: this.$route.params.id,
        client: this.dkDebt.BORROWER.id,
      }
      this.CREATE_CALL_HISTORY(req)
        .then(() => {
          this.disButton = false
          this.FETCH_SK_HEADER_STA({ debt_id: this.$route.params.id })
        })
    },

    changePerPage(pageSize) {
      this.params.page_size = pageSize
      this.FETCH_DK_DEBT_OTHER({
        BORROWER: this.dkDebt.BORROWER.id,
        id: this.$route.params.id,
        ...this.params,
      })
    },

    changePage(page) {
      this.params.page = page
      this.FETCH_DK_DEBT_OTHER({ BORROWER: this.dkDebt.BORROWER.id, id: this.$route.params.id, ...this.params })
    },

    onSubmit() {
      this.dkDebtOther.results.forEach(el => {
        if (el.promised_date) {
          this.show = true
          const {
            promised_date,
            promised_amount,
            id: debt,
          } = el
          const promisedObj = {
            promised_date,
            promised_amount,
            debt,
            user: this.getUserId,
            promise_type: this.promise_type,
          }
          this.CREATE_PROMISE(promisedObj)
            .then(() => {
              this.show = false
              this.disButton = true
              this.disButtonUp = true
              this.FETCH_PROMISE_LIST({ debt: this.$route.params.id })
              this.CREATE_HISTORY({
                debt: this.$route.params.id,
                status: 'warning',
              })
                .then(() => {
                  this.SET_EMPTY()
                  this.FETCH_HISTORY_TIMELINE({
                    debt: this.$route.params.id,
                    page_size: 4,
                  })
                  this.FETCH_SK_HEADER_STA({ debt_id: this.$route.params.id })
                  this.$_okToast()
                })
                .catch(e => this.$_errorToast(e))
            })
            .catch(e => {
              if (e && e.message === 'Request failed with status code 400') {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: e && e.response.data && e.response.data.detail,
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                }, {
                  position: 'bottom-right',
                })
              } else {
                this.$_errorToast(e)
              }
              this.show = false
            })
        } else {
          this.$_errorToast()
        }
      })
    },

    historyTimeLine(status) {
      this.show = true
      const req = {
        debt: this.$route.params.id,
        status,
      }

      this.CREATE_HISTORY(req)
        .then(() => {
          this.SET_EMPTY()
          this.FETCH_HISTORY_TIMELINE({
            debt: this.$route.params.id,
            page_size: 4,
          })
          this.FETCH_SK_HEADER_STA({ debt_id: this.$route.params.id })
          this.$_okToast()
          this.disButton = true
          this.disButtonUp = true
          this.show = false
        })
        .catch(e => {
          if (e && e.message === 'Request failed with status code 400') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: e && e.response.data && e.response.data.detail,
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            }, {
              position: 'bottom-right',
            })
          } else {
            this.$_errorToast(e)
          }
          this.show = false
        })
    },

    formattedPhoneNumber(value) {
      // Remove spaces and the plus sign
      value = value.replace(/\s/g, '').replace('+', '')

      // Check if the phone number is 11 digits long and starts with "7"
      if (value.length === 11 && value.startsWith('7')) {
        const regex = /^(7)(\d{3})(\d{3})(\d{2})(\d{2})$/
        const matches = value.match(regex)

        if (matches) {
          return `+${matches[1]} (${matches[2]}) ${matches[3]}-${matches[4]}-${matches[5]}`
        }
      }

      // Return empty string if no match found
      return ''
    },

    // formattedPhoneNumber(value) {
    //   const regex = /^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/
    //   const matches = value.match(regex)
    //
    //   if (matches) {
    //     return `+${matches[1]} (${matches[2]}) ${matches[3]}-${matches[4]}-${matches[5]}`
    //   }
    //
    //   return ''
    // },
  },
}
</script>
<style>
@import "./main.css";
</style>
