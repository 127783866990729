<template>
  <b-modal
    id="propertyModal"
    size="xl"
    centered
    title="Имущество"
    ok-variant="info"
    ok-title="Да"
    cancel-title="Отмена"
    hide-footer
    responsive
    no-close-on-backdrop
  >
    <b-table
      :fields="fields"
      :items="DEBTProperty.results"
      show-empty
      responsive
    >
      <template #empty>
        <empty />
      </template>

      <template #cell(SUM)="props">
        {{ $_moneyFormatter(props.item.SUM) }}
      </template>
    </b-table>

    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-0">
        <span class="text-nowrap mr-1">Показать по</span>
        <b-form-select
          v-model="filter.page_size"
          :options="[5, 10, 15]"
          class="mx-1"
          @change="refresh"
        />
        <span class="ml-1 text-nowrap"> строк( {{ DEBTProperty.count }} )</span>
      </div>
      <div>
        <b-pagination
          v-model="filter.page"
          :total-rows="DEBTProperty.count"
          :per-page="filter.page_size"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="changePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </b-modal>
</template>

<script>
import empty from '@components/empty.vue'
import {
  BFormSelect, BModal, BPagination, BTable,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BModal,
    BPagination,
    BFormSelect,
    BTable,
    empty,
  },
  data() {
    return {
      filter: {
        search: null,
        page: 1,
        page_size: 10,
      },
      fields: [
        {
          label: 'Модель и марка ТС',
          key: 'NAME',
        },
        {
          label: 'Тип ТС',
          key: 'TYPE_TC',
        },
        {
          label: 'VIN TC',
          key: 'VIN',
        },
        {
          label: 'Категория ТС',
          key: 'CATEGORY',
        },
        {
          label: 'Год выпуска ТС',
          key: 'YEAR_RELEASE',
        },
        {
          label: 'Стоимость ТС',
          key: 'SUM',
        },
      ],
    }
  },
  computed: {
    ...mapState('cases', ['DEBTProperty']),
  },
  methods: {
    refresh() {
      this.$emit('propertyRefresh', this.filter)
    },

    changePage(page) {
      this.filter.page = page

      this.refresh()
    },
  },
}
</script>
