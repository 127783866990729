<template>
  <b-modal
    id="reminderModal"
    title="Добавить напоминание"
    centered
    no-close-on-backdrop
    ok-title="Сохранить"
    cancel-title="Отменить"
    cancel-variant="outline-secondary"
    @show="openShowModal"
    @ok.prevent="onSubmit"
  >
    <ValidationObserver ref="reminderValidation">
      <b-form-group label="Дата">
        <ValidationProvider
          #default="{ errors }"
          name="Дата"
          rules="required"
        >
          <!--          <input-->
          <!--            id="passport_expire_date"-->
          <!--            v-model="item.reminding_date"-->
          <!--            type="date"-->
          <!--            class="form-control"-->
          <!--          >-->
          <flat-pickr
            v-model="item.reminding_date"
            :config="configdateTimePicker"
            class="form-control bg-white"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group label="Текст">
        <ValidationProvider
          #default="{ errors }"
          name="Текст"
          rules="required"
        >
          <b-form-textarea v-model="item.reminding_text" />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      required,
      item: {
        reminding_date: null,
        reminding_text: null,
      },
      configdateTimePicker: {
        dateFormat: 'd.m.Y',
        locale: Russian,
        minDate: 'today',
        maxDate: new Date().fp_incr(14),
      },
    }
  },
  computed: {
    ...mapState('collectorDetail', ['remiderList']),
  },
  methods: {
    ...mapActions('collectorDetail', ['CREATE_REMINDER', 'FETCH_REMINDER']),
    ...mapMutations('collectorDetail', ['SET_EMPTY_REMINDER']),

    onSubmit() {
      const params = {
        page_size: 5,
        debt: this.$route.params.id,
        page: 1,
      }

      this.$refs.reminderValidation.validate()
        .then(success => {
          if (success) {
            this.CREATE_REMINDER({ debt: this.$route.params.id, ...this.item })
              .then(() => {
                this.SET_EMPTY_REMINDER()
                this.$bvModal.hide('reminderModal')
                this.$_okToast()
                this.FETCH_REMINDER(params)
              })
              .catch(e => this.$_errorToast(e))
          }
        })
    },

    openShowModal() {
      this.item = {
        reminding_date: null,
        reminding_text: null,
      }
    },
  },
}
</script>
