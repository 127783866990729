var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modalAddWork","title":_vm.editItem.id ? 'Редактировать работа' : 'Добавить работа',"cancel-variant":"outline-secondary","centered":"","ok-title":"Сохранить","ok-variant":_vm.editItem.id ? 'warning' : 'primary',"cancel-title":"Отменить","no-close-on-backdrop":""},on:{"ok":_vm.onSubmit,"show":_vm.showModal}},[_c('validation-observer',{ref:"validation"},[_c('b-form-group',{attrs:{"label":"Вид дохода"}},[_c('validation-provider',{attrs:{"name":"Вид дохода","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","reduce":function (p) { return p.value; },"options":_vm.incomeType},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" К сожалению, нет подходящих вариантов ")]},proxy:true}],null,true),model:{value:(_vm.formData.INCOME_TYPE),callback:function ($$v) {_vm.$set(_vm.formData, "INCOME_TYPE", $$v)},expression:"formData.INCOME_TYPE"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Доход"}},[_c('validation-provider',{attrs:{"name":"Доход","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormInput',{attrs:{"type":"number"},model:{value:(_vm.formData.INCOME),callback:function ($$v) {_vm.$set(_vm.formData, "INCOME", $$v)},expression:"formData.INCOME"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Справка о доходах"}},[_c('v-select',{attrs:{"label":"title","reduce":function (p){ return p.value; },"options":_vm.incomeStatement},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" К сожалению, нет подходящих вариантов ")]},proxy:true}]),model:{value:(_vm.formData.NDFL2),callback:function ($$v) {_vm.$set(_vm.formData, "NDFL2", $$v)},expression:"formData.NDFL2"}})],1),_c('b-form-group',{attrs:{"label":"Компания"}},[_c('validation-provider',{attrs:{"name":"Компания","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"COMPANY_NAME","reduce":function (p) { return p.COMPANY_ID; },"options":_vm.debtCompanyList.results},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" К сожалению, нет подходящих вариантов ")]},proxy:true}],null,true),model:{value:(_vm.formData.COMPANY),callback:function ($$v) {_vm.$set(_vm.formData, "COMPANY", $$v)},expression:"formData.COMPANY"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Инн"}},[_c('validation-provider',{attrs:{"name":"Инн","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormInput',{attrs:{"type":"number"},model:{value:(_vm.formData.INN),callback:function ($$v) {_vm.$set(_vm.formData, "INN", $$v)},expression:"formData.INN"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Комментарий"}},[_c('BFormTextarea',{attrs:{"rows":"3"},model:{value:(_vm.formData.COMMENT),callback:function ($$v) {_vm.$set(_vm.formData, "COMMENT", $$v)},expression:"formData.COMMENT"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }