<template>
  <div
    style="height: 213px"
    class="overflow-auto"
  >
    <b-overlay
      :show="reminderLoad"
    >
      <app-timeline
        class="mt-2 position-relative pr-1"
      >
        <LazyScroll
          :other-params="{
            page_size: 5,
          }"
          :store-fetch="getFunc"
          :store-list="remiderList"
        >
          <app-timeline-item
            v-for="(e, inx) in remiderList.results"
            :key="inx"
            class="pb-2"
            variant="primary"
          >
            <div class="d-flex justify-content-between ">
              <div class="mb-sm-0">
                <h6 class="mb-0">
                  {{ e.reminding_text }}
                </h6>
              </div>
              <div class="d-flex flex-column flex-wrap text-right">
                <small class="mb-0 text-dark">
                  {{ e.reminding_date }}
                </small>
                <small
                  v-if="e.author"
                  class="timeline-item-time text-nowrap text-dark"
                >
                  {{ e.author }}
                </small>
              </div>
            </div>
          </app-timeline-item>
        </lazyscroll>

        <span
          v-if="remiderList.count === 0"
          class="w-100 d-block text-center"
        >
          Нет данных
        </span>
      </app-timeline>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import LazyScroll from '@components/LazyScroll/LazyScroll.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    LazyScroll,
    BOverlay,
  },
  data() {
    return {
      reminderLoad: false,
    }
  },
  computed: {
    ...mapState('collectorDetail', ['remiderList']),
  },
  async mounted() {
    try {
      this.reminderLoad = true
      this.SET_ACTIVETAB(true)
      const params = {
        page_size: 5,
        debt: this.$route.params.id,
        page: 1,
      }
      await this.FETCH_REMINDER(params)
    } catch (e) {
      this.reminderLoad = false
    }
    this.reminderLoad = false
  },
  methods: {
    ...mapActions('collectorDetail', ['FETCH_REMINDER']),
    ...mapMutations('collectorDetail', ['SET_ACTIVETAB']),

    getFunc() {
      const params = {
        page_size: 5,
        debt: this.$route.params.id,
        page: this.remiderList.page === 1 ? 1 : this.remiderList.next,
      }

      this.FETCH_REMINDER(params)
    },
  },
}
</script>
