<template>
  <b-modal
    id="debtInfo"
    title="Информация по должнику"
    centered
    size="lg"
    no-close-on-backdrop
    cancel-title="Отмена"
    ok-title="Сохранить"
    @show="openShowModal"
    @ok.prevent="onSubmit"
  >
    <b-row>
      <b-col md="6">
        <b-form-group label="Ф.И.О">
          <b-form-input v-model="value.FULL_NAME" />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group label="Дата рождения">
          <flat-pickr
            v-model="value.BIRTH_DATE"
            class="form-control"
            :config="configdateTimePicker"
          />
          <!--          <VcDatePicker-->
          <!--            v-model="value.BIRTH_DATE"-->
          <!--            show-format="DD.MM.YYYY"-->
          <!--          />-->
          <!--          <input-->
          <!--            v-model="value.BIRTH_DATE"-->
          <!--            type="date"-->
          <!--            class="form-control"-->
          <!--          >-->
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group label="Дата проживания и регистрации">
          <flat-pickr
            v-model="value.NOTARY_DATE"
            class="form-control"
            :config="configdateTimePicker"
          />
          <!--          <input-->
          <!--            v-model="value.NOTARY_DATE"-->
          <!--            type="date"-->
          <!--            class="form-control"-->
          <!--          >-->
          <!--        </b-form-group>-->
        </b-form-group></b-col>

      <b-col md="6">
        <b-form-group label="Дата выдачи паспорта:">
          <flat-pickr
            v-model="value.PASSPORT_GIVEN_DATE"
            class="form-control"
            :config="configdateTimePicker"
          />
          <!--          <input-->
          <!--            v-model="value.PASSPORT_GIVEN_DATE"-->
          <!--            type="date"-->
          <!--            class="form-control"-->
          <!--          >-->
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group label="Паспорт серия">
          <b-form-input v-model="value.PASSPORT_SERIA" />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group label="Номер паспорта">
          <b-form-input
            v-model="value.PASSPORT_NUMBER"
            type="number"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/extensions
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import {
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    flatPickr,
  },
  data() {
    return {
      value: {
        FULL_NAME: null,
        BIRTH_DATE: null,
        NOTARY_DATE: null,
        PASSPORT_GIVEN_DATE: null,
        PASSPORT_SERIA: null,
        PASSPORT_NUMBER: null,
      },
      configdateTimePicker: {
        dateFormat: 'd.m.Y',
        locale: Russian,
      },
    }
  },
  computed: {
    ...mapState('collectorDetail', ['dkDebt']),
  },
  methods: {
    ...mapActions('collectorDetail', ['UPDATE_BASIS_CONTACT', 'FETCH_DK_DEBT_ID']),

    onSubmit() {
      const { id } = this.dkDebt.BORROWER
      const { value } = this

      this.UPDATE_BASIS_CONTACT({ id, data: value })
        .then(() => {
          this.$bvModal.hide('debtInfo')
          this.FETCH_DK_DEBT_ID({ id: this.$route.params.id })
          this.$_okToast()
        })
        .catch(e => this.$_errorToast(e))
    },

    onSelectDate(value) {
      if (value) {
        this.value.BIRTH_DATE = moment(value).format('DD.MM.YYYY')
      }
    },

    openShowModal() {
      const {
        FULL_NAME,
        BIRTH_DATE,
        NOTARY_DATE,
        PASSPORT_GIVEN_DATE,
        PASSPORT_SERIA,
        PASSPORT_NUMBER,
      } = this.dkDebt.BORROWER

      this.value = {
        FULL_NAME,
        BIRTH_DATE,
        NOTARY_DATE,
        PASSPORT_GIVEN_DATE,
        PASSPORT_SERIA,
        PASSPORT_NUMBER,
      }
    },
  },
}
</script>
<style>
@import '~flatpickr/dist/flatpickr.css';
</style>
