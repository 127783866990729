<template>
  <div>
    <BModal
      ref="modal"
      :size="addBtn ? 'xl' : 'lg'"
      centered
      title="Работа"
      ok-variant="outline-secondary"
      ok-title="Закрыты"
      ok-only
      hide-footer
      no-close-on-backdrop
    >
      <div
        v-if="addBtn"
        class="text-right"
      >
        <BButton
          v-b-modal.modalAddWork
          variant="primary"
          @click="editItem = {}"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Добавить источник дохода
        </BButton>
      </div>
      <BTable
        class="my-2"
        :items="statementOfDebt.results"
        :fields="addBtn ? fields : fields2"
        show-empty
        empty-text="Нет данных"
      >
        <!--        addBtn-->
        <template #cell(POSITION)="props">
          <span>{{ props.item.POSITION === 'None' ? '' : props.item.POSITION }}</span>
        </template>
        <!--        addBtn-->

        <template #cell(INCOME_TYPE)="props">
          <span v-if="props.item.INCOME_TYPE === 'work'">Пенсия</span>
          <span v-if="props.item.INCOME_TYPE === 'pension'">Другое</span>
          <span v-if="props.item.INCOME_TYPE === 'other'">Работающий</span>
          <span v-if="props.item.INCOME_TYPE === 'work_pensioner'">пенсионер</span>
        </template>
        <template #cell(actions)="props">
          <div>
            <BButton
              class="p-50 mr-1"
              variant="warning"
              @click="editRow(props.item)"
            >
              <feather-icon icon="EditIcon" />
            </BButton>
            <BButton
              class="p-50"
              variant="danger"
              @click="deleteData(props.item.id)"
            >
              <feather-icon icon="XIcon" />
            </BButton>
          </div>
        </template>
      </BTable>
      <BCPagination
        :go-to-page="false"
        :count="statementOfDebt.count"
        @input="onRefresh"
      />
    </BModal>

    <addWorkmodal
      :edit-item="editItem"
    />
  </div>
</template>

<script>
import {
  BModal, BTable, BButton,
} from 'bootstrap-vue'
import addWorkmodal from '@/modules/statementDebtor/components/addWorkmodal.vue'
import { mapActions, mapState } from 'vuex'
import areYouSure from '@/mixins/areYouSure'

export default {
  name: 'TheDebtorIncomeModal',
  components: {
    BModal,
    BTable,
    BButton,
    addWorkmodal,
  },
  mixins: [areYouSure],
  props: {
    addBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fields: [
        {
          label: 'Вид дохода',
          key: 'INCOME_TYPE',
          sortable: true,
        },
        {
          label: 'Доход',
          key: 'INCOME',
        },
        {
          label: 'Справка о доходах',
          key: 'NDFL2',
        },
        {
          label: 'Компания',
          key: 'COMPANY_NAME',
        },
        {
          label: 'ИНН',
          key: 'INN',
        },
        {
          label: 'Комментарий',
          key: 'COMMENT',
        },
        {
          label: 'Действия',
          key: 'actions',
        },
      ],
      fields2: [
        {
          label: 'наименование места работы',
          key: 'COMPANY',
        },
        {
          label: 'Трудоустройство',
          key: 'POSITION',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      editItem: {},
    }
  },

  computed: {
    ...mapState('cases', ['statementOfDebt']),
  },

  methods: {
    ...mapActions('cases', ['DELETE_SK_STATEMENT_OF_DEBT', 'FETCH_SK_STATEMENT_OF_DEBT']),
    open() {
      this.$refs.modal.show()
    },
    close() {
      this.$refs.modal.hide()
    },
    editRow(item) {
      this.editItem = item
      this.$bvModal.show('modalAddWork')
    },
    checkItemId(item) {
      return item.id === this.formData.id
    },

    onRefresh(data) {
      this.$emit('workModal', data)
    },

    deleteData(id, data) {
      this.$_showAreYouSureModal()
        .then(val => {
          if (val) {
            this.DELETE_SK_STATEMENT_OF_DEBT(id)
              .then(() => {
                this.$nextTick(() => {
                  this.$emit('workModal', data)
                  // this.FETCH_SK_STATEMENT_OF_DEBT({ id: this.$route.params.id })
                  this.$_okToast()
                })
              })
              .catch(err => {
                this.$_errorToast(err)
              })
          }
        })
    },
  },
}
</script>
<style lang="scss">
.select-w-z-index {
  min-width: 250px;
  width: 250px;
  z-index: 9999;
}
</style>
